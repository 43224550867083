import Route from "@ember/routing/route";
import { hash } from "rsvp";
import RankableGroup from "rankables/models/rankable-group";
import Rankable from "rankables/models/rankable";
import { inject as service } from "@ember/service";
import produce from "immer";

import HasuraService from "rankables/services/hasura";

interface ParametersInterface {
  "rankable_id": string
}

interface IndexTasksModel extends Object {
  params: unknown;
  rankableGroup: RankableGroup;
}

type IndexTasksCompareRouteModel = {
  origin: Rankable | RankableGroup;
  rankings: string[];
  rankable: Rankable;
}

export default class IndexTasksCompareRoute extends Route {

  @service declare hasura: HasuraService;

  async model(params: ParametersInterface): Promise<IndexTasksCompareRouteModel> {
    const indexTasksIndexRoute: IndexTasksModel = this.modelFor("index.tasks") as IndexTasksModel;
    let rankable: Rankable = await this.hasura.findRecord("rankable", params["rankable_id"]);

    let parentRankable: Rankable | null = await this.hasura.findRecord("rankable", rankable.parent_rankable_id);


    /** This is referring to the rankable or rankable group that's the original, e.g. the parent rankable for children
     * and the rankableGroup for top level rankables 
     */
    let origin: RankableGroup | Rankable | null = parentRankable;


    /**
     * When the rankable has a parent, then we will do the comparison based on the sibling rankables
     * as opposed to the rankable group ones.
     */
    if (!origin) {
      origin = indexTasksIndexRoute.rankableGroup;
    }

    return hash({
      origin: origin,
      rankings: origin.data.rankings?.slice(0) || new Array(),
      rankable: rankable,
      parentRankable: parentRankable,
    });
  };

  /**
   * TODO: One issue is that we're using the rankables that are with the model by default.
   * Rather than do that, our goal should be to simply query the store (Hasura) directly, e.g.
   * if the rankings for the rankableGroup are ['a', 'b-dead', 'c'] we want try to find
   * 'b-dead'. If 'b-dead' doesn't exist we will update the rankings to be ['a', 'c'] and 
   *  redo the query. We will do this until we find something or there are no rankings left.
   *  This should be a relatively rare situation and the probablistic expected runtime of this
   *  should be O(1) as only one query should be necessary in most situations.
   */
  async afterModel(model: any) {
    let rankable: Rankable = model.rankable;
    let rankableId = rankable.id;

    // Represents the rankable group rankings, not including the current ranking being compared
    model.rankingsWithoutModelRankings = produce(model.rankings, (draftRankings: any) => {
      draftRankings = draftRankings.filter((id: string) => { return id !== rankableId })
      return draftRankings;
    });


    // Changes necessary to reset ranking status:
    model.previousCurrentRankableId = "";
    model.previousComparison = null;
    model.upperBound = model.rankingsWithoutModelRankings.length - 1;
    model.middle = Math.floor((model.rankings.length - 1)/2);
    model.lowerBound = 0;
    model.rankable = rankable;

    let getCurrentRankable = async function(this: IndexTasksCompareRoute, {originalRankings, originalId, rankings, middle, origin}: any): Promise<Rankable|null> {
      let rankableId = rankings[model.middle];

      // This would happen if rankings is an empty array.
      if (!rankableId) {
        return null;
      }

      let rankable: Rankable | null;

      try {
        rankable = await this.hasura.findRecord("rankable", rankableId);
      } catch(e) {
        rankable = null;
      }

      // We would see null if the ID was stale (e.g. the underlying rankable was deleted and wasn't
      // updated in the rankings)
      if (rankable) {
        return rankable
      }

      // Update origin, delete faulty item from rankings
      let newRankings: string[]  = originalRankings.filter((item: string) => { return item !== rankableId });

      await this.hasura.updateRecord(origin, {
        data: {
          rankings: newRankings
        }
      });

      return getCurrentRankable.bind(this)({
        originalRankings,
        originalId,
        rankings: newRankings.filter(id => id !== originalId),
        middle,
        origin
      })

    };

    model.currentRankable = await getCurrentRankable.bind(this)({
      originalRankings: model.rankings,
      originalId: rankableId,
      rankings: model.rankingsWithoutModelRankings,
      middle: model.middle,
      origin: model.origin
    });

    const unsetRank = rankable.rank === 0 || rankable.rank === undefined;

    let origin = model.origin;
    const noExistingRankings = origin.data.rankings?.length === 0 || origin.data.rankings === undefined;

    if (unsetRank && noExistingRankings) {
      await this.hasura.updateRecord(origin, {
        rank_value: 90000000
      });

      await this.hasura.updateRecord(origin, {
        data: {
          rankings: []
        }
      });
    }
  };

}
