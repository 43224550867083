import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";

import { action } from "@ember/object";

import Rankable from "rankables/models/rankable";

interface UiTopRankablesListComponentArgs {
  loadElements: () => Promise<Rankable[]>;
}

export default class UiTopRankablesListComponent extends Component<UiTopRankablesListComponentArgs> {
  @tracked
  topRankables?: Promise<Rankable[]>;

  @action
  insertElement(this: UiTopRankablesListComponent) {
    let rankables: Promise<Rankable[]> = this.args.loadElements();
    this.topRankables = rankables;
  }
};
