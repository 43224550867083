import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";

import { action } from "@ember/object";

import Rankable from "rankables/models/rankable";

interface UiWidgetListComponentArgs {
  title: string;
  key: "created_on" | "updated_on";
  loadElements: () => Promise<Rankable[]>;
}

export default class UiWidgetListComponent extends Component<UiWidgetListComponentArgs> {
  @tracked
  rankables?: Promise<Rankable[]>;

  @action
  insertElement(_element: HTMLElement) {
    let rankables: Promise<Rankable[]> = this.args.loadElements();
    this.rankables = rankables;
  }

};
