import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import Rankable from "rankables/models/rankable";

interface UiRankablesListArgs {
}

export default class UiRankablesList extends Component<UiRankablesListArgs> {
  @tracked
  rankables!: Rankable[];

  constructor(owner: any, args: any) {
    super(owner, args);
  }
}

