import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";

interface UiEditRankableGroupArgs {
}

export default class UiEditRankableGroupComponent extends Component<UiEditRankableGroupArgs> {
  @tracked
  editingNow: boolean;

  @tracked
  temporaryRankableGroupTitle: string;

  @tracked
  temporaryRankableGroupDescription: string;

  constructor(owner: any, args: any) {
    super(owner, args);
    this.editingNow = false;
    this.temporaryRankableGroupTitle = "";
    this.temporaryRankableGroupDescription = "";
  }
}

