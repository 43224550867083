import RankableObject from "rankables/models/rankable-object";

import tagMutation from "rankables/gql/mutations/create-tag.graphql";
import tagDeleteMutation from "rankables/gql/mutations/delete-tag.graphql";
import tagQueryByPk from "rankables/gql/queries/get-tag-pk.graphql";
import RankablesTagQ from "rankables/gql/queries/get-tag.graphql";
import TagModelsGqlQuery from "rankables/pods/index/tasks/index/tag-model-query.graphql";
import TagModel from "rankables/models/tag-model";

// Hasura Model autogen
import {
  RankablesTag_rankables_tag as ApolloModel,
  RankablesTag as ApolloQuery
} from "rankables/gql/queries/__generated__/RankablesTag";

import {
  InsertRankablesTag_insert_rankables_tag,
  InsertRankablesTag_insert_rankables_tag_returning
} from "rankables/gql/mutations/__generated__/InsertRankablesTag";

import {
  DeleteRankablesTagByPk_delete_rankables_tag_by_pk
} from "rankables/gql/mutations/__generated__/DeleteRankablesTagByPk";

import ApolloService from "ember-apollo-client/services/apollo";

import { tracked } from "@glimmer/tracking";

import { v4 as uuidv4 } from 'uuid';
import { reduce, assign } from "lodash";

const HASURA_TYPENAME = "rankables_tag";

export default class Tag extends RankableObject implements ApolloModel, HasuraModel<Tag> {

  @tracked rankable_group_id!: string;
  @tracked name!: string;
  @tracked description!: string;
  @tracked data!: any;
  test: any;
  __typename: typeof HASURA_TYPENAME = HASURA_TYPENAME;

  /** Local only attributes */
  apollo?: ApolloService

  static graphQlName = "rankables_tag";
  static recordDeleteMutation = tagDeleteMutation;
  static recordDeleteMutationVariables = function(model: Tag) {
    return {
      id: model.id
    }
  }
  static recordUpsertionMutation = tagMutation;
  static recordUpsertMutationVariables = function(model: Tag) {
    return {
      id: model.id,
      created_on: model.created_on,
      updated_on: model.updated_on,
      description: model.description,
      name: model.name,
      rankable_group_id: model.rankable_group_id
    }
  }

  // Hasura
  static async findAll(apollo: ApolloService, options: any = { fetchPolicy: "network-only" }): Promise<Tag[]> {
    let queryResult: ApolloQuery = await apollo.query(assign({
      query: RankablesTagQ
    }, options));

    return queryResult[HASURA_TYPENAME].map((record: ApolloModel) => {
      let model = new Tag(record);
      model.apollo = apollo;
      return model;
    });
  }

  static async findRecord(apollo: ApolloService, id: string, options?: any): Promise<Tag | null> {
    let record: Tag;

    try {
      record = await apollo.query(Object.assign({
        query: tagQueryByPk,
        variables: { id: id }
      }, options), `${HASURA_TYPENAME}_by_pk`);
    } catch(e) {
      return null;
    }

    if (!record) {
      return null;
    }

    let rankable = new Tag(record);
    rankable.apollo = apollo;
    return rankable;
  }

  /**
   * Makes the Hasura GraphQL request, and optionally casts all data to the given type's model, if
   * defined.
   */
  static async query(
    apollo: ApolloService,
    gql: {query: any, variables?: { [index: string]: string|number } , name: string},
    options: any = { fetchPolicy: "network-only" }
  ): Promise<any> {
    let records = await apollo.query(Object.assign({
      query: gql.query,
      variables: gql.variables
    }, options), gql.name);

    let newRecords = records.map((record: any) => {
      let tag = new Tag(record);
      tag.apollo = apollo;
      return tag;
    });

    return newRecords;
  }

  async updateRecord(this: Tag, data: Partial<ApolloModel>, options: any = { fetchPolicy: "network-only" }): Promise<Tag> {
    if (this.apollo) {
      let keys = Object.keys(this.constructor.prototype).pushObjects(
        Object.keys(this.constructor.prototype.constructor.superclass.prototype)
      );

      let apolloObjectState = reduce(keys, (obj: any, key: string) => {
        // @ts-ignore
        obj[key] = this[key];
        return obj;
      }, {});

      let variables = assign(apolloObjectState, data);

      let mutation: InsertRankablesTag_insert_rankables_tag = await this.apollo.mutate(assign({
        mutation: tagMutation,
        variables: variables
      }, options), `insert_${this.__typename}`)

      return mutation.returning.map((record: InsertRankablesTag_insert_rankables_tag_returning) => {
        return new Tag(record);
      })[0];
    }

    throw "Apollo not loaded into model";
  }

  static async createRecord(apollo: ApolloService, data: Partial<ApolloModel>): Promise<Tag> {
    let id = uuidv4();

    if (!data.id ) {
      data.id = id;
    }

    if (!data.updated_on) {
      data.updated_on = new Date();
    }

    if (!data.created_on) {
      data.created_on = new Date();
    }

    if (!data.data) {
      data.data = {};
    }

    let mutation: InsertRankablesTag_insert_rankables_tag = await apollo.mutate({
      mutation: tagMutation,
      variables: data
    }, `insert_${HASURA_TYPENAME}`);

    let record = new Tag(mutation.returning[0]);
    record.apollo = apollo;
    return record;
  }

  async destroyRecord(this: Tag, options = {}): Promise<DeleteRankablesTagByPk_delete_rankables_tag_by_pk> {
    if (this.apollo) {
      let deletion: DeleteRankablesTagByPk_delete_rankables_tag_by_pk = await this.apollo.mutate(Object.assign({
        mutation: tagDeleteMutation,
        variables: {
          id: this.id
        }
      }, options), `delete_${this.__typename}_by_pk`);

      return deletion;
    } else {
      throw "Apollo not set onto model";
    }
  }


  // End Hasura

  /**
   * Gets the associated TagModels of the current Tag.
   */
  getTagModels(this: Tag, service: any, options: any = {}): Promise<TagModel[]> {
    let variables: any = {
      tag_id: this.id
    }

    // options: { where: { rankable_id: { _eq: 1234-123-12 } } }
    if (options?.where) {
      // TODO: If necessary we will want to make this not hardcoded
      variables["rankable_id"] = options.where["rankable_id"]["_eq"];

    }

    let query = service.query({
      query: TagModelsGqlQuery,
      variables,
      name: "rankables_tag_model"
    })

    return query;
  }
};
