import { helper } from "@ember/component/helper";
import momentjs from "moment";

function moment([action, ...args]: [string, any]) {
  if (action === "fromNow") {
    return momentjs(...args).fromNow();
  }

  return;
}

export default helper(moment);
