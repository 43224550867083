import Route from "@ember/routing/route";
import { hash } from "rsvp";
import RankableGroup from "rankables/models/rankable-group";
import { inject as service } from "@ember/service";

import Tag from "rankables/models/tag";

import HasuraService from "rankables/services/hasura";

//@ts-ignore
import gqlQuery from "./query.graphql";

interface IndexTasksTagRouteQueryParams {
  sort: "created_on" | "updated_on";
  direction: "asc" | "desc";
  show_unranked: boolean;
}

export default class IndexTasksTagRoute extends Route {
  @service declare hasura: HasuraService;

  queryParams = {
    sort: { refreshModel: true },
    direction: { refreshModel: true },
    show_unranked: { refreshModel: true }
  };

  async model(params: IndexTasksTagRouteQueryParams) {
    //@ts-ignore
    const taskParams = this.modelFor("index.tasks").params;
    //@ts-ignore
    const rankableGroup: RankableGroup = await this.hasura.findRecord("rankable-group", taskParams["rankable_group_id"]);

    const query = gqlQuery;
    const variables: any = {
      rankable_group_id: rankableGroup.id
    }

    if (params.sort && params.direction) {
      let sortField: any = {};
      sortField[params.sort] = params.direction;
      variables["order_by"] = {};
      variables["order_by"][params.sort] = params.direction;
    }

    let tags: Tag[] = await this.hasura.query({
      query,
      variables,
      name: "rankables_tag"
    });

    return hash({
      rankableGroup: rankableGroup,
      tags: tags
    });
  };

};
