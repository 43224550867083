import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";

interface UiEditRankableArgs {
}

export default class UiEditRankableComponent extends Component<UiEditRankableArgs> {
  @tracked
  editingNow: boolean;

  @tracked
  temporaryRankableTitle: string;

  @tracked
  temporaryRankableDescription: string;


  constructor(owner: any, args: any) {
    super(owner, args);
    this.editingNow = false;
    this.temporaryRankableTitle = "";
    this.temporaryRankableDescription = "";
  }
}

