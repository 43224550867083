import Route from "@ember/routing/route";
import { inject as service } from "@ember/service";

import FirebaseService from "rankables/services/firebase";

export default class IndexLogoutRoute extends Route {
  @service declare firebase: FirebaseService;
  transition: any;

  beforeModel(this: IndexLogoutRoute, transition: any) {
    this.transition = transition;
  }

  actions = {

    async didTransition(this: IndexLogoutRoute) {
      await this.firebase.signOut();
      this.transitionTo("index");
    }
  }
};
