import Helper from "@ember/component/helper";

export default class DatetimeLocalString extends Helper {
  compute([date]) {
    if (!date) {
      return null;
    }

    let now = new Date(date);
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    return now.toISOString().slice(0,16);
  }


};
