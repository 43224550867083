import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";

import { action, get } from "@ember/object";
import { inject as service } from "@ember/service";

import TopTenQuery from "./top-ten-query.graphql";
import LatestRankablesQuery from "./latest-rankables-query.graphql";
import UpdatedRankablesQuery from "./updated-rankables-query.graphql";
import IndexGroupRoute from "./route";

import HasuraService from "rankables/services/hasura";
import RankableGroup from "rankables/models/rankable-group";

//@ts-ignore
import t from "tailored";

const $ = t.variable();
const _ = t.wildcard();

export default class IndexGroupController extends Controller {

  get dropdownIndicator(): string {
    return t.defmatch(
      t.clause(["created_on"], () => "Creation Date"),
      t.clause(["updated_on"], () => "Last Modified Date"),
      t.clause(["title"], () => "Title"),
      t.clause(["rank"], () => "Rank"),
      t.clause([_], () => null)
    )(this.sort)
  };

  get ascending(): boolean {
    if (!this.sort) {
      return false;
    }

    return this.direction === "asc";
  };

  route!: IndexGroupRoute;

  @service declare hasura: HasuraService;
  queryParams = ["sort", "direction"];

  @tracked
  sort?: string | null;

  @tracked
  direction?: string | null;
  rankableGroupTitle?: string | null;

  /*
   * New rankables groups as they're added.
   */
  newRankableGroups!: RankableGroup[];

  @action
  async loadLatestRankables(this: IndexGroupController): Promise<any> {
    return get(this, "hasura").query({
      query: LatestRankablesQuery,
      variables: {},
      name: "rankables_rankable"
    });
  }

  @action
  async loadRecentlyUpdatedRankables(this: IndexGroupController): Promise<any> {
    return get(this, "hasura").query({
      query: UpdatedRankablesQuery,
      variables: {},
      name: "rankables_rankable"
    });
  }

  @action
  async loadTopRankables(this: IndexGroupController): Promise<any> {
    return get(this, "hasura").query({
      query: TopTenQuery,
      variables: {},
      name: "rankables_rankable"
    });
  }

  @action
  toggleDirection(this: IndexGroupController) {
    if (get(this, "direction") === "desc") {
      this.route.transitionTo("index.groups", {
        queryParams: { direction: "asc" }
      });
    } else {
      this.route.transitionTo("index.groups", {
        queryParams: { direction: "desc" }
      });

    }
  }

  @action
  deleteRankableGroup() {
    console.log("Implement me");
  }

  @action
  addNewRankableGroup(
    this: IndexGroupController,
    rankableGroupTitle: string,
    store: any,
    event: FocusEvent) {
    event.preventDefault();

    t.defmatch(
      t.clause([{
        rankableGroupTitle: $,
        store: $,
        event: $
      }], async (rankableGroupTitle: string,
        hasura: HasuraService,
        event: any) => {
          let rankableGroup: RankableGroup = await hasura.createRecord("rankable-group", {
            title: rankableGroupTitle,
            data: {
              rankings: []
            }
          });

          get(this, "newRankableGroups").pushObject(rankableGroup);

          const eventTarget: any = event.target;
          eventTarget.value = null;
          this.rankableGroupTitle = null;

        }, (rankableGroupTitle: string,
          hasura: any,
          event: any) => {
            return Boolean(rankableGroupTitle) &&
              Boolean(hasura) &&
              Boolean(event)
          }
      ),

      t.clause([_], () => {
        console.log("Perhaps some sort of error later?");
      })

    )({ rankableGroupTitle, store, event });
  }
}
