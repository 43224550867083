import Service from "@ember/service";
import { run } from "@ember/runloop";

import { tracked } from "@glimmer/tracking";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

//@ts-ignore

export default class FirebaseService extends Service {
  @tracked currentUser: any;
  @tracked currentlyLoggedIn: boolean;
  @tracked currentToken?: string;
  @tracked currentParsedToken?: any;
  firebaseInstance: any;
  onAuthStatePromise!: Promise<any>;

  constructor() {
    super(...arguments);

    let firebaseConfig = {
      apiKey: "AIzaSyB9fmp_r_ehstdr-bFIVXWrbhO-7B38MOc",
      authDomain: "lexicoi.firebaseapp.com",
      projectId: "lexicoi",
      storageBucket: "lexicoi.appspot.com",
      messagingSenderId: "801823753905",
      appId: "1:801823753905:web:f67b5f5ee02c2b62740490",
      measurementId: "G-R19G6J7JP6"
    };


    // Initialize Firebase
    try {
      firebase.initializeApp(firebaseConfig);
    } catch(e) {
    }

    this.currentUser = firebase.auth().currentUser;
    this.currentlyLoggedIn = Boolean(this.currentUser);
    this.firebaseInstance = firebase;

    this.onAuthStatePromise = new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(function(this: FirebaseService, user: any) {
        try {
          if (user) {
            resolve(user);
            this.currentUser = user;
            this.currentlyLoggedIn = true;
          } else {
            resolve(null);
          }
        } catch(e) {
          reject();
        }
      }.bind(this));
    });
  }

  /**
   * This will get the idToken and set it to the service. This is necessary so on every call to
   * apollo the token is either completely fresh or the cached one is used.
   */
  async refreshAuthState() {
    try {
      this.currentToken = await this.currentUser.getIdToken();
      this.currentParsedToken = await this.currentUser.getIdTokenResult();
    } catch(e) {
      this.getCurrentUser();
    }
  }

  /**
   * This is used to force Firebase to load before anything can be done. The issue being that if
   * this function is not called, when you got to a rankable group directly, Firebase may not be
   * loaded which will cause the application to be in an error state.
   */
  getCurrentUser(this: FirebaseService) {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  async signOut(this: FirebaseService) {
    try {
      await firebase.auth().signOut();

      this.currentUser = null;
      this.currentlyLoggedIn = false;
      this.currentToken = "";
      this.currentParsedToken = null;
    } catch(e) {
      throw e;
    }
  }

  async login(this: FirebaseService, username: string, password: string, rememberMe: boolean) {
    await run(async function() {
      try {
        if (rememberMe) {
          await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        } else {
          await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        }

        return firebase.auth().signInWithEmailAndPassword(username, password);
      } catch(e) {
        throw e;
      }
    });
  };

  async register(this: FirebaseService, username: string, password: string, passwordConfirmation: string) {
    if (!username) {
      let error = new Error();
      error.message = "Please enter an email address";
      throw error;
      return;
    }

    if (password !== passwordConfirmation) {
      let error = new Error();
      error.message = "Please ensure that your passwords are equal";
      throw error;
      return;
    }

    try {
      await firebase.auth().createUserWithEmailAndPassword(username, password);
    } catch(e) {
      throw e;
    }


  }
};

declare module "@ember/service" {
  interface Registry {
    "firebase": FirebaseService
  }
}
