import Component from "@ember/component";
import { action } from "@ember/object";
import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import Paragraph from "@tiptap/extension-paragraph";


export default class Tiptap extends Component {

  constructor() {
    super(...arguments);
  }

  didInsertElement(this: Tiptap) {
    if (!this) { 
      return
    }

    const self = this;
    this.editor = new Editor({
      onTransaction({ editor }) {
        self.updateText(editor.getHTML());
      },

      element: document.querySelector("#editor") as HTMLElement,
      extensions: [
        Document,
        Paragraph,
        Text,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        StarterKit,
      ],
      content: self.content || "<p>Change me to whatever text you would like!</p>"
    });
  }

  content!: string;
  updateText!: Function;
  editor: any;

  // TODO: Turn this into a helper, or something.
  @action
  eval(str: string): void {
    eval(str);
    return;
  }

};
