import rankableGroupMutation from "rankables/gql/mutations/create-rankable-group.graphql";
import RankablesRankableGroupQ from "rankables/gql/queries/get-rankable-group.graphql";
import rankableGroupQueryByPk from "rankables/gql/queries/get-rankable-group-pk.graphql";
import rankableGroupDeleteMutation from "rankables/gql/mutations/delete-rankable-group.graphql";
import RankableGroupTagsQuery from "rankables/pods/index/tasks/task/tags-query.graphql";
import Tag from "../models/tag";

import ApolloService from "ember-apollo-client/services/apollo";
import { tracked } from "@glimmer/tracking";

import { v4 as uuidv4 } from 'uuid';
import gql from "graphql-tag";

import { reduce, assign } from "lodash";

// Hasura Model autogen
import {
  RankablesRankableGroup_rankables_rankable_group as ApolloModel ,
  RankablesRankableGroup as ApolloQuery
} from "rankables/gql/queries/__generated__/RankablesRankableGroup";

import {
  InsertRankablesRankableGroup_insert_rankables_rankable_group,
  InsertRankablesRankableGroup_insert_rankables_rankable_group_returning
} from "rankables/gql/mutations/__generated__/InsertRankablesRankableGroup";

import {
  DeleteRankablesRankableGroupByPk_delete_rankables_rankable_group_by_pk
} from "rankables/gql/mutations/__generated__/DeleteRankablesRankableGroupByPk";


import RankableObject from "rankables/models/rankable-object";
import RankableGroupSettings from "rankables/models/rankable-group-settings";

const HASURA_TYPENAME = "rankables_rankable_group";
export default class RankableGroup extends RankableObject implements ApolloModel, HasuraModel<RankableGroup> {

  [index: string]: any;

  @tracked title!: string | null;
  @tracked description!: string | null;
  @tracked data: {
    rankings?: string[] | null;
  } = {}

  @tracked rankable_group_settings_id!: string | null;
  @tracked rankables_user_id!: string | null;

  apollo?: ApolloService;
  __typename: typeof HASURA_TYPENAME = HASURA_TYPENAME;

  constructor(data: Partial<ApolloModel>) {
    super(data);
    return this;
  }

  static graphQlName = HASURA_TYPENAME;
  static recordFindByPkQuery = rankableGroupQueryByPk;
  static recordUpsertionMutation = rankableGroupMutation;
  static recordUpsertMutationVariables = function(model: RankableGroup) {
    return {
      id: model.id,
      created_on: model.created_on,
      updated_on: model.updated_on,
      title: model.title,
      description: model.description,
      data: model.data
    }
  }

  // Hasura
  static async findAll(apollo: ApolloService, options: any = { fetchPolicy: "network-only" }): Promise<RankableGroup[]> {
    let queryResult: ApolloQuery = await apollo.query(Object.assign({
      query: RankablesRankableGroupQ
    }, options));

    return queryResult[HASURA_TYPENAME].map((record: ApolloModel) => {
      let model = new RankableGroup(record);
      model.apollo = apollo;
      return model;
    });
  }

  static async findRecord(apollo: ApolloService, id: string, options?: any): Promise<RankableGroup | null> {
    let record: RankableGroup;

    try {
      record = await apollo.query(Object.assign({
        query: rankableGroupQueryByPk,
        variables: { id: id }
      }, options), `${HASURA_TYPENAME}_by_pk`);
    } catch(e) {
      return null;
    }

    if (!record) {
      return null;
    }

    let rankableGroup = new RankableGroup(record);
    rankableGroup.apollo = apollo;
    return rankableGroup;
  }

  async updateRecord(this: RankableGroup, data: Partial<ApolloModel>, options: any = { fetchPolicy: "network-only" }): Promise<RankableGroup> {
    if (this.apollo) {
      let keys = Object.keys(this.constructor.prototype).pushObjects(
        Object.keys(this.constructor.prototype.constructor.superclass.prototype)
      );

      let apolloObjectState = reduce(keys, (obj: any, key: string) => {
        obj[key] = this[key];
        return obj;
      }, {});

      let mutation: InsertRankablesRankableGroup_insert_rankables_rankable_group = await this.apollo.mutate(assign({
        mutation: rankableGroupMutation,
        variables: assign(apolloObjectState, data)
      }, options), `insert_${this.__typename}`)

      return mutation.returning.map((record: InsertRankablesRankableGroup_insert_rankables_rankable_group_returning) => {
        return new RankableGroup(record);
      })[0];
    }

    throw "Apollo not loaded into model";
  }

  static async createRecord(apollo: ApolloService, data: Partial<ApolloModel>): Promise<RankableGroup> {
    let id = uuidv4();

    if (!data.id ) {
      data.id = id;
    }

    if (!data.updated_on) {
      data.updated_on = new Date();
    }

    if (!data.created_on) {
      data.created_on = new Date();
    }

    if (!data.data) {
      data.data = {};
    }

    let mutation: InsertRankablesRankableGroup_insert_rankables_rankable_group = await apollo.mutate({
      mutation: rankableGroupMutation,
      variables: data
    }, `insert_${HASURA_TYPENAME}`);

    let record = new RankableGroup(mutation.returning[0]);
    record.apollo = apollo;
    return record;
  }

  async destroyRecord(this: RankableGroup, options = {}): Promise<DeleteRankablesRankableGroupByPk_delete_rankables_rankable_group_by_pk> {
    if (this.apollo) {
      //@ts-ignore
      let deletion: DeleteRankablesRankableGroupByPk_delete_rankables_rankable_group_by_pk = await this.apollo.mutate(Object.assign({
        mutation: rankableGroupDeleteMutation,
        variables: {
          id: this.id
        }
      }, options), `delete_${this.__typename}_by_pk`);

      return deletion;
    } else {
      throw "Apollo not set onto model";
    }
  }

  async columnMutation(this: RankableGroup, name: string, value: any, options: any = { fetchPolicy: "network-only" }) {
    let mutationGql: any;
    switch(name) {
      case "data":
        mutationGql = gql`
          mutation MyMutation($id: uuid!, $value: jsonb) {
            update_rankables_rankable_group_by_pk(
              pk_columns: {
                id: $id
              },
              _set: {
                data: $value
              }) {
              id
            }
          }`
        break;
      case "title":
        mutationGql = gql`
          mutation MyMutation($id: uuid!, $value: String) {
            update_rankables_rankable_group_by_pk(
              pk_columns: {
                id: $id
              },
              _set: {
                title: $value
              }) {
              id
            }
          }`

        break;
      case "description":
        mutationGql = gql`
          mutation MyMutation($id: uuid!, $value: String) {
            update_rankables_rankable_group_by_pk(
              pk_columns: {
                id: $id
              },
              _set: {
                description: $value
              }) {
              id
            }
          }`

        break;
    }

    if (this.apollo) {
      await this.apollo.mutate(assign({
        mutation: mutationGql,
        variables: {
          id: this.id,
          value: value
        }
      }, options), `update_${this.__typename}_by_pk`)

      //@ts-ignore
      this[name] = value;
      return this;
    } else {
      throw "Apollo no set onto model";
    }
  }

  // End Hasura
  async getSettings(this: RankableGroup): Promise<RankableGroupSettings | null> {
    let rankableGroupSettingsQuery = gql`
      query getRankableGroupSettings($id: uuid = "") {
        rankables_rankable_group_settings(where: {rankable_group_id: {_eq: $id}}) {
          id
          rankable_group_id
          rg__include_created
          rg__include_modified
          rg__include_top
        }
      }`

    if (this.apollo) {
      let rankableGroupSettings: RankableGroupSettings[] = await this.apollo.query({
        query: rankableGroupSettingsQuery,
        variables: {
          id: this.id
        },
        name: "rankables_rankable_group_settings"
      }, "rankables_rankable_group_settings");

      if (!rankableGroupSettings[0]) {
        return null;
      }

      let newRgs = new RankableGroupSettings(rankableGroupSettings[0]);
      newRgs.apollo = this.apollo;
      return newRgs;
    } else {
      throw "Apollo no set onto model";
    }
  }

  getTags(this: RankableGroup, service: any): Promise<Tag[]> {
    return service.query({
      query: RankableGroupTagsQuery,
      variables: {
        rankable_group_id: this.id,
      },
      name: "rankables_tag"
    });

  }

}
