import Route from '@ember/routing/route';
import settingsList from "./settings";

import { inject as service } from "@ember/service";
import ApolloService from "rankables/services/apollo";
import RankableGroup from "rankables/models/rankable-group";
import RankableGroupSettings from "rankables/models/rankable-group-settings";

export default class IndexTasksSettings extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service declare apollo: ApolloService;

  async model() {
    let rankableGroup: RankableGroup = this.modelFor("index.tasks").rankableGroup;
    let rankableGroupSettings: RankableGroupSettings | null = await rankableGroup.getSettings();


    if (!rankableGroupSettings) {
      rankableGroupSettings = await RankableGroupSettings.createRecord(this.apollo, {
        rankable_group_id: rankableGroup.id
      });

    }

    if (!rankableGroup.rankable_group_settings_id) {
      await rankableGroup.updateRecord({ rankable_group_settings_id: rankableGroupSettings.id });
    }

    return {
      rankableGroup: rankableGroup,
      rankableGroupSettings: rankableGroupSettings,
    }
  }

  setupController(controller: any, model: any) {
    controller.set("model", model);
    controller.set("settingsList", settingsList);
  }
}
