import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";

interface UiAddNewRankableArgs {
}

export default class UiAddNewRankableComponent extends Component<UiAddNewRankableArgs> {
  @tracked
  rankableTitle: string | null;

  @tracked
  rankableDescription: string | null;

  constructor(owner: any, args: any) {
    super(owner, args);
    this.rankableTitle = null;
    this.rankableDescription = null;
  }
}
