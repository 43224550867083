import Controller from "@ember/controller";
import RankableGroup from "rankables/models/rankable-group";
import { bubbleReset } from "rankables/utils/actions";
import { inject as service } from "@ember/service";


import HasuraService from "rankables/services/hasura";

//@ts-ignore
import t from "tailored";

const $ = t.variable();
const _ = t.wildcard();

export default class IndexTasksController extends Controller {

  @service declare hasura: HasuraService;

  rankableGroup!: RankableGroup;
  rankableTitle?: string | null;
  rankableDescription?: string | null;

  actions = {

    bubbleReset(event: Event) {
      bubbleReset(event);
    },

    updateAndSave(
      this: IndexTasksController,
      model: RankableGroup,
      attribute: string,
      value: string,
      event: Event): void {
        if (event) { event.preventDefault(); }
        t.defmatch(
          t.clause([$, "title", $], async function(this: IndexTasksController, model: RankableGroup, value: string) {
            this.hasura.updateRecord(model, { title: value });
            if (event.target) {
              ((event.target as HTMLElement).querySelector("[value=cancel]") as HTMLButtonElement).click();
            }
          }.bind(this)),

          t.clause([$, "description", $], async function(this: IndexTasksController, model: RankableGroup, value: string) {
            this.hasura.updateRecord(model, { description: value });
            if (event.target) {
              ((event.target as HTMLElement).querySelector("[value=cancel]") as HTMLButtonElement).click();
            }
          }.bind(this)),

          t.clause([_], () => {
            throw "Something went wrong";
          })
        )(model, attribute, value)
      },

    updateRankableGroupTitle(this: IndexTasksController, rankableGroup: RankableGroup, event: any) {
      if (event && event.target) {
        if (event.target.value === rankableGroup.title) {
          return
        }

        this.rankableGroup.title = event.target.value;
        this.hasura.updateRecord(rankableGroup, { title: event.target.value });
      }
    },

    addNewRankable(
      this: IndexTasksController,
      rankableTitle: string,
      rankableDescription: string,
      rankableGroup: RankableGroup,
      store: any,
      event: FocusEvent) {
      event.preventDefault();

      t.defmatch(
        t.clause([{
          rankableTitle: $,
          rankableDescription: $,
          rankableGroup: $,
          store: $,
          event: $
        }], async (rankableTitle: string,
          rankableDescription: string,
          rankableGroup: RankableGroup,
          store: any,
          event: any) => {
            await store.createRecord("rankable", {
              title: rankableTitle,
              description: rankableDescription,
              rankable_group_id: rankableGroup.id
            });

            const eventTarget: any = event.target;
            eventTarget.value = null;
            this.rankableTitle = null;
            this.rankableDescription = null;
          }, (rankableTitle: string,
            rankableDescription: string,
            rankableGroup: RankableGroup,
            store: any,
            event: any) => {
              return Boolean(rankableTitle) &&
                Boolean(rankableDescription) &&
                Boolean(rankableGroup) &&
                Boolean(store) &&
                Boolean(event)
            }
        ),

        t.clause([{
          rankableTitle: $,
          rankableGroup: $,
          store: $,
          event: $
        }], async (rankableTitle: string,
          rankableGroup: RankableGroup,
          store: any,
          event: any) => {
            await store.createRecord("rankable", {
              title: rankableTitle,
              rankable_group_id: rankableGroup.id
            }).save();

            const eventTarget: any = event.target;
            eventTarget.value = null;
            this.rankableTitle = null;
          }, (rankableTitle: string,
            rankableGroup: RankableGroup,
            store: any,
            event: any) => {
              return Boolean(rankableTitle) &&
                Boolean(rankableGroup) &&
                Boolean(store) &&
                Boolean(event)
            }
        ),

        t.clause([_], () => {
          console.log("Perhaps some sort of error later?");
        })

      )({ rankableTitle, rankableDescription, rankableGroup, store, event });
    }
  }
}
