import Controller from "@ember/controller";
import { inject as service } from "@ember/service";
import { get } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class LoginController extends Controller.extend({
  firebase: service("firebase")
}) {
  /**
   * @param - The username (generaly email)
   */
  @tracked
  username: string = "";

  /**
   * @param - The password, generally 8 or more characters 
   */
  @tracked
  password: string = "";

  /**
   * @param - Sets the persistence leve in Firebase to LOCAL if true
   */
  rememberMe: boolean = false;;

  /**
   * @param - Refers to the error state passed from firebase
   */
  error: any;

  actions = {

    /**
     * Logs into user account via firebase
     *
     * @param username - See LoginController.username
     * @param password - See LoginController.password
     * @param rememberMe - See LoginController.rememberMe
     * @param event - The Form Event used to submit
     */
    async login(this: LoginController, username: string, password: string, rememberMe: boolean, event: Event): Promise<void> {
      event.preventDefault();

      try {
        await get(this, "firebase").login(username, password, rememberMe);
        this.username = "";
        this.password = "";
        this.transitionToRoute("index");


      } catch(e) {
        this.error = e;
      }
    }

  }
}
