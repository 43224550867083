/**
 * A list of all of the settings names
 */
export default [
  {
    header: "Include in 'Top Rankables' widget",
    description: `Disabling this will <em>exclude</em> this Rankable Group and all associated
    rankables from being included in the "top" rankables widget`,
    settingName: "rg__include_top",
    valueType: "static",
    validValuesMap: [
      { name: "Default" },
      { name: "Enable", value: true },
      { name: "Disable", value: false }
    ],
  },
  {
    header: "Include in 'Newly Created' widget",
    description: `Disabling this will <em>exclude</em> this Rankable Group and all associated
    rankables from being included in the "newly created" rankables widget`,
    settingName: "rg__include_created",
    valueType: "static",
    validValuesMap: [
      { name: "Default" },
      { name: "Enable", value: true },
      { name: "Disable", value: false }
    ],
  },
  {
    header: "Include in 'Newly modified' widget",
    description: `Disabling this will <em>exclude</em> this Rankable Group and all associated
    rankables from being included in the "newly modified" rankables widget`,
    settingName: "rg__include_modified",
    valueType: "static",
    validValuesMap: [
      { name: "Default" },
      { name: "Enable", value: true },
      { name: "Disable", value: false }
    ],
  }
]
