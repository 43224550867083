import Controller from "@ember/controller";
import { computed, action, notifyPropertyChange, set, get } from "@ember/object";
import { A } from "@ember/array";
import { inject as service } from "@ember/service";

import { tracked } from "@glimmer/tracking";

import Rankable from "rankables/models/rankable";
import RankableGroup from "rankables/models/rankable-group";
import Tag from "rankables/models/tag";
import TagModel from "rankables/models/tag-model";
import { bubbleReset, deleteRankable } from "rankables/utils/actions";
import HasuraService from "rankables/services/hasura";


//@ts-ignore
import t from "tailored";

//@ts-ignore
import { RxDocument } from "@ember/service";

const $ = t.variable();
const _ = t.wildcard();

export default class IndexTasksTaskController extends Controller {

  @service declare hasura: HasuraService;

  @computed("rankableTagModels")
  get tagModelMap(): { [index: string]: TagModel } {
    interface TagModelMap {
      [tagId: string]: TagModel
    }
    let map: TagModelMap = {};
    this.rankableTagModels.forEach(tagModel => map[tagModel.tag_id] = tagModel);
    return map;
  }


  /**
   * The tags that correspond to the tagModels that
   * are actually being used, in this case.
   */
  @computed("rankableGroupTags")
  get filteredTags(): (Tag | undefined)[] {
    return this.rankableGroupTags.filter(tag => Boolean(this.tagModelMap[tag.id]));
  }

  @tracked
  selectedTag!: Tag;

  @tracked
  origin!: Rankable | RankableGroup;

  @tracked
  rankableGroup!: RankableGroup;

  @tracked
  rankable!: Rankable;

  @tracked
  rankables!: Rankable[];

  @tracked
  rankableGroupTags: Tag[];

  @tracked
  rankableTagModels: TagModel[];

  @tracked
  moreRankables?: boolean = false;

  @tracked
  rankablesQueryRequestParams!: { query: any, variables: any };

  @tracked
  queryParams = ["sort", "direction"];

  @tracked
  sort: "rank_value" | "created_on" | "updated_on" = "rank_value";

  @tracked
  direction: "desc" | "asc" = "desc";

  @tracked
  show_unranked: boolean = true;

  @tracked
  tag_filter: string | null = null;

  @tracked
  temporaryRankableTitle?: string | null;

  @tracked
  temporaryRankableTitleMessage?: string | null;

  @tracked
  temporaryRankableDescription?: string | null;

  @tracked
  temporaryRankableDescriptionMessage?: string | null;

  @tracked
  temporaryRankableDetails?: string | null;

  @tracked
  temporaryRankableDetailsMessage?: string | null;


  /* Child rankable title and description */
  @tracked
  rankableTitle?: string | null;

  @tracked
  rankableDescription?: string | null;

  constructor() {
    super(...arguments);
    this.rankableGroupTags = [];
    this.rankableTagModels = [];
    this.rankables = A([]);
  }

    /**
     * Loads more rankables using the saved parameters from
     * the initial request.
     */
  @action
  async loadMoreRankables(this: IndexTasksTaskController) {
    let rankables: Array<Rankable> = get(this, "rankables");
    let lastRankable: Rankable = rankables[rankables.length - 1];

    let { query, variables } = get(this, "rankablesQueryRequestParams");

    let direction: "asc" | "desc" = get(this, "direction");
    let sortVariable: "rank_value" | "updated_on" | "created_on" = get(this, "sort");
    let lastRankableValue: any = lastRankable[sortVariable];

    if (!variables[sortVariable]) {
      variables[sortVariable] = {};
      variables["created_on"] = {};
    }

    if (direction === "asc") {
      variables[sortVariable]["_gte"] = lastRankableValue;
      variables["created_on"]["_gt"] = lastRankable["created_on"];

    } else if (direction === "desc") {
      variables[sortVariable]["_lte"] = lastRankableValue;
      variables["created_on"]["_lt"] = lastRankable["created_on"];
    }

    // Get more rankables, accordingly to the sort
    let additionalRankables: Rankable[] = await this.hasura.query({
      query,
      variables,
      name: "rankables_rankable"
    });

    if (additionalRankables.length > 0) {
      additionalRankables.forEach(rankable => get(this, "rankables").pushObject(rankable));
    }

    // Less than 10 means an additional request would yield no rankables due to our limit of 10
    // per batch of rankables.
    if (additionalRankables.length < 10) {
      set(this, "moreRankables", false);
    }

    return;
  }

  @action
  setTag(this: IndexTasksTaskController, tag: Tag) {
    set(this, "tag_filter", tag.id);
  }

  @action
  sortBy(this: IndexTasksTaskController, value: string) {
    let [ direction, sort ] = value.split("-");

    this.transitionToRoute("index.tasks.task", {
      queryParams: {
        direction,
        sort
      }
    });
  }

    @action
    addNewChildRankable(
      this: IndexTasksTaskController,
      args: {
        rankable: Rankable,
        rankableTitle: string,
        rankableDescription: string,
        rankableGroup: RankableGroup,
        hasura: any
      }, event: Event) {
      let { rankable, rankableTitle, rankableDescription, rankableGroup, hasura} = args;

      event.preventDefault();

      t.defmatch(
        t.clause([{
          rankable: $,
          rankableTitle: $,
          rankableDescription: $,
          rankableGroup: $,
          hasura: $,
          event: $
        }], async (parentRankable: Rankable,
          rankableTitle: string,
          rankableDescription: string,
          rankableGroup: RankableGroup,
          hasura: any,
          event: any) => {
            let rankable: Rankable = await hasura.createRecord("rankable", {
              title: rankableTitle,
              description: rankableDescription,
              parent_rankable_id: parentRankable.id,
              rankable_group_id: rankableGroup.id
            });

            bubbleReset(event);
            rankable.justAdded = true;
            this.rankables.unshiftObject(rankable);
            notifyPropertyChange(this, "rankables");
          }, (rankable: Rankable,
            rankableTitle: string,
            rankableDescription: string,
            rankableGroup: RankableGroup,
            hasura: any,
            event: any) => {
              return Boolean(rankable) &&
                Boolean(rankableTitle) &&
                Boolean(rankableDescription) &&
                Boolean(rankableGroup) &&
                Boolean(hasura) &&
                Boolean(event)
            }
        ),

        t.clause([{
          rankable: $,
          rankableTitle: $,
          rankableGroup: $,
          hasura: $,
          event: $
        }], async (parentRankable: Rankable,
          rankableTitle: string,
          rankableGroup: RankableGroup,
          hasura: any,
          event: any) => {
            let [ rankable ] : [ Rankable ] = await hasura.createRecord("rankable", {
              title: rankableTitle,
              parent_rankable_id: parentRankable.id,
              rankable_group_id: rankableGroup.id
            });

            bubbleReset(event);
            rankable.justAdded = true;
            this.rankables.unshiftObject(rankable);
            notifyPropertyChange(this, "rankables");

          }, (rankable: Rankable,
            rankableTitle: string,
            rankableGroup: RankableGroup,
            hasura: any,
            event: any) => {
              return Boolean(rankable) &&
                Boolean(rankableTitle) &&
                Boolean(rankableGroup) &&
                Boolean(hasura) &&
                Boolean(event)
            }
        ),


        t.clause([_], () => {
          console.log("Perhaps some sort of error later?");
        })

      )({ rankable, rankableTitle, rankableDescription, rankableGroup, hasura, event });
    }

  @action
  bubbleReset(event: Event) {
    bubbleReset(event);
  }


  @action
  async updateTagModel(
    this: IndexTasksTaskController,
    rankableGroupTag: Tag) {

    let tagModelMap = get(this, "tagModelMap");
    let tagModel: TagModel = tagModelMap[rankableGroupTag.id]
    // It's present - remove the tag
    if (tagModel?.id) {
      await this.hasura.destroyRecord(tagModel);
      delete tagModelMap[rankableGroupTag.id]
      set(this, "tagModelMap", tagModelMap);
      set(this, "filteredTags", this.rankableGroupTags.filter(tag => Boolean(tagModelMap[tag.id])));

      // Tag not present, add the tag
    } else {
      let hasura = get(this, "hasura");
      let rankable = get(this, "rankable");

      if (rankable) {
        let newTagModel = await hasura.createRecord("tag-model", {
          tag_id: rankableGroupTag.id,
          rankable_id: rankable.id,
        });

        tagModelMap[rankableGroupTag.id] = (newTagModel as TagModel);
        set(this, "tagModelMap", tagModelMap);
        set(this, "filteredTags", this.rankableGroupTags.filter(tag => Boolean(this.tagModelMap[tag.id])));
      }
    }

    notifyPropertyChange(this, "filteredTags");
    notifyPropertyChange(this, "tagModelMap");
  }

  @action
  async updateAndSave(
    this: IndexTasksTaskController,
    model: Rankable,
    attribute: string,
    value: string,
    event: Event) {
    if (event) { event.preventDefault(); }

    const self: IndexTasksTaskController = this;

    t.defmatch(
      t.clause([$, "title", $], async (model: Rankable, value: string) => {
        let newRankable: Rankable = await self.hasura.updateRecord(model, { title: value });
        self.rankable.title = newRankable.title;
        self.temporaryRankableTitleMessage = "Changes saved";
        setTimeout(function() {
          set(self, "temporaryRankableTitle", null);
          set(self, "temporaryRankableTitleMessage", null);
        }, 3000)
      }),

      t.clause([$, "description", $], async (model: Rankable, value: string) => {
        let newRankable: Rankable = await self.hasura.updateRecord(model, { description: value });
        self.rankable.description = newRankable.description;
        self.temporaryRankableDescriptionMessage = "Changes saved";
        setTimeout(function() {
          set(self, "temporaryRankableDescriptionMessage", null);
          set(self, "temporaryRankableDescription", null);
        }, 3000)

      }),

      t.clause([$, "details", $], async (model: Rankable, value: string) => {
        let newRankable: Rankable = await self.hasura.updateRecord(model, { details: value });
        self.rankable.details = newRankable.details;
        self.temporaryRankableDetailsMessage = "Changes saved";
        setTimeout(function() {
          set(self, "temporaryRankableDetailsMessage", null);
          set(self, "temporaryRankableDetails", null);
        }, 3000)

      }),

      t.clause([_], () => {
        throw "Something went wrong";
      })
    )(model, attribute, value);
  }

  /**
   * Delete the rankable and transitions to the parent route.
   * @param rankable - The child rankable to be deleted
   */
  @action
  async deleteRankable(this: IndexTasksTaskController, rankable: Rankable, event: MouseEvent) {
    event.preventDefault();

    await deleteRankable(get(this, "hasura"), rankable);

    // DEBT: This might have performance issues with super large (>= 10,000) datasets, but
    // it's O(n) so it cannot be improved that significantly.
    this.rankables.removeObject(rankable);
    notifyPropertyChange(this, "rankables");
    this.transitionToRoute("index.tasks", this.rankableGroup.id);
  }
}
