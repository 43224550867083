import Controller from "@ember/controller";
import { action } from "@ember/object";

export default class IndexTasksSettings extends Controller.extend({
}) {

  @action
  updateValue(settingName: string, event: Event) {
    let target = event.target as HTMLSelectElement;
    let value: any = target.value;
    if (value === "true") {
      value = true;
    } else if (value === "false") {
      value = false;
    }

    this.model.rankableGroupSettings.columnMutation(settingName, value);
  }
  // normal class body definition here
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'index/tasks/settings': IndexTasksSettings;
  }
}
