import RankablesRankableGroupSettingsQ from "rankables/gql/queries/get-rankable-group-settings.graphql";
import rankableGroupSettingsMutation from "rankables/gql/mutations/create-rankable-group-settings.graphql";
import rankableGroupSettingsQueryByPk from "rankables/gql/queries/get-rankable-group-settings-pk.graphql";
import rankableGroupSettingsDeleteMutation from "rankables/gql/mutations/delete-rankable-group-settings.graphql";

import RankableObject from "rankables/models/rankable-object";

import { tracked } from "@glimmer/tracking";

import { v4 as uuidv4 } from 'uuid';
import gql from "graphql-tag";

import { reduce, assign } from "lodash";

import ApolloService from "ember-apollo-client/services/apollo";

// Hasura Model autogen
import {
  RankablesRankableGroupSettings_rankables_rankable_group_settings as ApolloModel ,
  RankablesRankableGroupSettings as ApolloQuery
} from "rankables/gql/queries/__generated__/RankablesRankableGroupSettings";

import {
  InsertRankablesRankableGroupSettings_insert_rankables_rankable_group_settings,
  InsertRankablesRankableGroupSettings_insert_rankables_rankable_group_settings_returning
} from "rankables/gql/mutations/__generated__/InsertRankablesRankableGroupSettings";

import {
  DeleteRankablesRankableGroupSettingsByPk_delete_rankables_rankable_group_settings_by_pk
} from "rankables/gql/mutations/__generated__/DeleteRankablesRankableGroupSettingsByPk";

const HASURA_TYPENAME = "rankables_rankable_group_settings";
export default class RankableGroupSettings extends RankableObject implements ApolloModel, HasuraModel<RankableGroupSettings> {

  @tracked rankable_group_id: string;
  @tracked rg__include_top: boolean | null;
  @tracked rg__include_created: boolean | null;
  @tracked rg__include_modified: boolean | null;

  __typename: typeof HASURA_TYPENAME = HASURA_TYPENAME;

  /** Local only attributes */
  apollo?: ApolloService;
  justAdded!: boolean;

  constructor(data: ApolloModel) {
    super(data);
    this.rankable_group_id = data.rankable_group_id;
    this.rg__include_top = data.rg__include_top;
    this.rg__include_created= data.rg__include_created;
    this.rg__include_modified = data.rg__include_modified;
    return this;
  }

  // Hasura
  static async findAll(apollo: ApolloService, options: any = { fetchPolicy: "network-only" }): Promise<RankableGroupSettings[]> {
    let queryResult: ApolloQuery = await apollo.query(assign({
      query: RankablesRankableGroupSettingsQ
    }, options));

    return queryResult[HASURA_TYPENAME].map((record: ApolloModel) => {
      let model = new RankableGroupSettings(record);
      model.apollo = apollo;
      return model;
    });
  }
  static async findRecord(apollo: ApolloService, id: string, options?: any): Promise<RankableGroupSettings | null> {
    let record: RankableGroupSettings;

    try {
      record = await apollo.query(Object.assign({
        query: rankableGroupSettingsQueryByPk,
        variables: { id: id }
      }, options), `${HASURA_TYPENAME}_by_pk`);
    } catch(e) {
      return null;
    }

    if (!record) {
      return null;
    }

    let rankableGroupSettings = new RankableGroupSettings(record);
    rankableGroupSettings.apollo = apollo;
    return rankableGroupSettings;
  }

  async updateRecord(this: RankableGroupSettings, data: Partial<ApolloModel>, options: any = { fetchPolicy: "network-only" }): Promise<RankableGroupSettings> {
    if (this.apollo) {
      let keys = Object.keys(this.constructor.prototype).pushObjects(
        Object.keys(this.constructor.prototype.constructor.superclass.prototype)
      );

      let apolloObjectState = reduce(keys, (obj: any, key: string) => {
        //@ts-ignore
        obj[key] = this[key];
        return obj;
      }, {});

      let variables = assign(apolloObjectState, data);

      let mutation: InsertRankablesRankableGroupSettings_insert_rankables_rankable_group_settings = await this.apollo.mutate(assign({
        mutation: rankableGroupSettingsMutation,
        variables: variables
      }, options), `insert_${this.__typename}`)

      return mutation.returning.map((record: InsertRankablesRankableGroupSettings_insert_rankables_rankable_group_settings_returning) => {
        return new RankableGroupSettings(record);
      })[0];
    }

    throw "Apollo not loaded into model";
  }

  // TODO: Set this up such that optional fields are actually  reflected in the type, e.g. Partial,
  // but makes nullable fields optional and makes everything else required.
  static async createRecord(apollo: ApolloService, data: Partial<ApolloModel>): Promise<RankableGroupSettings> {
    let id = uuidv4();

    if (!data.id ) {
      data.id = id;
    }

    let mutation: InsertRankablesRankableGroupSettings_insert_rankables_rankable_group_settings = await apollo.mutate({
      mutation: rankableGroupSettingsMutation,
      variables: data
    }, `insert_${HASURA_TYPENAME}`);

    let record = new RankableGroupSettings(mutation.returning[0]);
    record.apollo = apollo;
    return record;
  }

  async destroyRecord(this: RankableGroupSettings, options = {}): Promise<DeleteRankablesRankableGroupSettingsByPk_delete_rankables_rankable_group_settings_by_pk> {
    if (this.apollo) {
      //@ts-ignore
      let deletion: DeleteRankablesRankableGroupSettingsByPk_delete_rankables_rankable_group_settings_by_pk = await this.apollo.mutate(Object.assign({
        mutation: rankableGroupSettingsDeleteMutation,
        variables: {
          id: this.id
        }
      }, options), `delete_${this.__typename}_by_pk`);

      return deletion;
    } else {
      throw "Apollo not set onto model";
    }
  }

  /**
   * Mutation to only change a specific column.
   */
  async columnMutation(this: RankableGroupSettings, name: string, value: any, options: any = { fetchPolicy: "network-only" }) {
    let mutationGql: any;
    switch(name) {
      case "rg__include_created":
        mutationGql = gql`
          mutation MyMutation($id: uuid!, $value: Boolean) {
            update_rankables_rankable_group_settings_by_pk(
              pk_columns: {
                id: $id
              },
              _set: {
                rg__include_created: $value
              }) {
              id
            }
          }`

        break;

      case "rg__include_modified":
         mutationGql = gql`
          mutation MyMutation($id: uuid!, $value: Boolean) {
            update_rankables_rankable_group_settings_by_pk(
              pk_columns: {
                id: $id
              },
              _set: {
                rg__include_modified: $value
              }) {
              id
            }
          }`

        break;

      case "rg__include_top":
         mutationGql = gql`
          mutation MyMutation($id: uuid!, $value: Boolean) {
            update_rankables_rankable_group_settings_by_pk(
              pk_columns: {
                id: $id
              },
              _set: {
                rg__include_top: $value
              }) {
              id
            }
          }`

        break;

      default:
        throw "Column not implemented. Please implement mutation for unimplemented column"
    };

    if (this.apollo) {
      await this.apollo.mutate(assign({
        mutation: mutationGql,
        variables: {
          id: this.id,
          value: value
        }
      }, options), `update_${this.__typename}_by_pk`)

      //@ts-ignore
      this[name] = value;
      return this;
    } else {
      throw "Apollo no set onto model";
    }

  }

  //End Hasura


  static recordFindByPkQuery = rankableGroupSettingsQueryByPk;
}
