//@ts-ignore
import ApolloService, { ApolloLink }  from "ember-apollo-client/services/apollo";
import { setContext } from "@apollo/client/link/context";
import { get } from "@ember/object";
import { inject as service } from "@ember/service";

import FirebaseService from "rankables/services/firebase";

export default class OverridenApolloService extends ApolloService {

  @service declare firebase: FirebaseService;

  link(this: OverridenApolloService) {
    let httpLink = super.link();
    let authLink = setContext(async (_request, _context) => {
      //@ts-ignore
      await get(this, "firebase").refreshAuthState();

      //@ts-ignore
      if (get(this, "firebase.currentlyLoggedIn")) {
        //@ts-ignore
        let jwt: ApolloLink = get(this, "firebase.currentToken");
        return {
          headers: {
            "Authorization": `Bearer ${jwt}`
          }
        }
      } else {
        return;
      }

    });

    //@ts-ignore
    return authLink.concat(httpLink);
  }
};

declare module "@ember/service" {
  interface Registry {
    "apollo": OverridenApolloService
  }
}
