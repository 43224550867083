import Controller from "@ember/controller";
import Rankable from "rankables/models/rankable";
import RankableGroup from "rankables/models/rankable-group";
import { alias } from '@ember/object/computed';
//@ts-ignore
import tailored from "tailored";
import { resetRankings } from "./utils";
import { inject as service } from "@ember/service";

import HasuraService from "rankables/services/hasura";

const $ = tailored.variable();

export default class IndexTasksCompareController extends Controller {
  model: any;

  @alias("model.currentRankable") currentRankable!: Rankable | null;
  @alias("model.previousCurrentRankableId") previousCurrentRankableId!: string;
  @alias("model.previousComparison") previousComparison!: string;
  @alias("model.lowerBound") lowerBound!: number;
  @alias("model.upperBound") upperBound!: number;
  @service declare hasura: HasuraService;

  constructor() {
    super(...arguments);
  }

  async getCurrentRankable(this: IndexTasksCompareController, {originalRankings, originalId, rankings, middle, origin}: any): Promise<Rankable> {
    let rankableId = rankings[this.model.middle];
    let rankable: Rankable = await this.hasura.findRecord("rankable", rankableId);

    // We would see null if the ID was stale (e.g. the underlying rankable was deleted and wasn't
    // updated in the rankings)
    if (rankable) {
      return rankable
    }

    // Update origin, delete faulty item from rankings
    let newRankings: string[]  = originalRankings.filter((item: string) => { return item !== rankableId });

    await this.hasura.updateRecord(origin, {
      data: {
        rankings: newRankings
      }
    });

    return this.getCurrentRankable.bind(this)({
      originalRankings,
      originalId,
      rankings: newRankings.filter(id => id !== originalId),
      middle,
      origin
    })

  }

  actions = {
    compare(this: IndexTasksCompareController, comparison: string, currentRankable: Rankable): void {
      tailored.defmatch(
        tailored.clause(["lesser", { id: $ }], async (currentRankableId: string) => {
          this.model.lowerBound = this.model.middle;
          this.model.currentRankableId = currentRankableId;
          this.model.previousComparison = "lesser";

          /**
           * The second clause is necessary to handle the case where there are an
           * even number of ranked items
           */
          if (Math.abs(this.model.upperBound - this.model.lowerBound) === 1 ||
            (this.model.rankingsWithoutModelRankings.length % 2 === 0 &&
              Math.abs(this.model.upperBound - this.model.lowerBound) === 2)) {
            this.model.middle = this.model.upperBound;
            this.model.lowerBound = this.model.middle;
            this.currentRankable = await this.getCurrentRankable({
              originalRankings: this.model.rankings,
              originalId: this.model.rankable.id,
              rankings: this.model.rankingsWithoutModelRankings,
              middle: this.model.middle,
              origin: this.model.origin
            });

          } else if (this.model.middle === this.model.upperBound && this.model.middle === this.model.lowerBound) {
            this.currentRankable = null;

          } else {
            this.model.middle = Math.floor((this.model.upperBound + this.model.lowerBound) / 2);
            this.currentRankable = await this.getCurrentRankable({
              originalRankings: this.model.rankings,
              originalId: this.model.rankable.id,
              rankings: this.model.rankingsWithoutModelRankings,
              middle: this.model.middle,
              origin: this.model.origin
            });
          }
        }),

        tailored.clause(["greater", { id: $ }], async (currentRankableId: string) => {
          this.model.upperBound = this.model.middle;
          this.model.previousCurrentRankableId = currentRankableId;
          this.model.previousComparison = "greater";

          if (Math.abs(this.model.upperBound - this.model.lowerBound) === 1) {
            this.model.middle = this.model.lowerBound;
            this.model.upperBound = this.model.middle;
            this.currentRankable = await this.getCurrentRankable({
              originalRankings: this.model.rankings,
              originalId: this.model.rankable.id,
              rankings: this.model.rankingsWithoutModelRankings,
              middle: this.model.middle,
              origin: this.model.origin
            });

          } else if (this.model.middle === this.model.upperBound && this.model.middle === this.model.lowerBound) {
            this.currentRankable = null;
          } else {
            this.model.middle = Math.floor((this.model.upperBound + this.model.lowerBound) / 2);
            this.currentRankable = await this.getCurrentRankable({
              originalRankings: this.model.rankings,
              originalId: this.model.rankable.id,
              rankings: this.model.rankingsWithoutModelRankings,
              middle: this.model.middle,
              origin: this.model.origin
            });
          }

        })
      )(comparison, currentRankable)
    },

    async saveComparison(this: IndexTasksCompareController, comparison: string, event: MouseEvent): Promise<void> {
      if (event && event.target) {
        event.preventDefault();
      }

      const rankable: Rankable = this.model.rankable;
      const origin: Rankable | RankableGroup = this.model.origin;

      /**
       * Determines whether or not the rankabled currently being ranked is present in the tentative
       * rankings.
       */
      let tentativeRankings: string[];

      try {
        tentativeRankings = this.model.origin.data.rankings.filter((item: string) => {
          return item !== rankable.id
        });

        // origin.data is equal to "" or rankings doesn't exist on the object
      } catch(e) {
        tentativeRankings = [];
      }

      if (comparison === "lesser") {
        tentativeRankings.splice(this.model.middle + 1, 0, rankable.id);
      } else {
        tentativeRankings.splice(this.model.middle, 0, rankable.id);
      }


      /**
       * This represents the initial case. If there have been no items ranked, the first;
       * item to be ranked is saved with the inital values.
       */
      if (this.model.rankingsWithoutModelRankings.length === 0) {
        await this.hasura.updateRecord(rankable, {
          rank: 1,
          rank_value: 90000000
        });

        await this.hasura.updateRecord(origin, {
          data: {
            rankings: [rankable.id]
          }
        });

      } else {

        await this.hasura.updateRecord(origin, {
          data: {
            rankings: tentativeRankings
          }
        });

        await resetRankings(this.hasura, tentativeRankings, this.model.rankable);
      }

      if (origin.__typename === "rankables_rankable") {
        this.transitionToRoute("index.tasks.task", origin.id);
      } else if (origin.__typename === "rankables_rankable_group") {
        this.transitionToRoute("index.tasks", origin.id);
      } else {
        // TODO: Properly handle this later
        throw "Something went wrong";
      }
    }
  }
}
