import Controller from "@ember/controller";
import { inject as service } from "@ember/service";

export default class IndexProfileController extends Controller.extend({
  firebase: service("firebase")
}) {
  actions = {
  }

};
