import RankablesRankableEventQ from "rankables/gql/queries/get-rankable-event.graphql";
import rankableEventMutation from "rankables/gql/mutations/create-rankable-event.graphql";
import rankableEventQueryByPk from "rankables/gql/queries/get-rankable-event-pk.graphql";
import rankableEventDeleteMutation from "rankables/gql/mutations/delete-rankable-event.graphql";

import RankableObject from "rankables/models/rankable-object";

import { tracked } from "@glimmer/tracking";

import { v4 as uuidv4 } from 'uuid';

import { reduce, assign } from "lodash";

import ApolloService from "ember-apollo-client/services/apollo";

// Hasura Model autogen
import {
  RankablesRankableEvent_rankables_rankable_event as ApolloModel ,
  RankablesRankableEvent as ApolloQuery
} from "rankables/gql/queries/__generated__/RankablesRankableEvent";

import {
  InsertRankablesRankableEvent_insert_rankables_rankable_event,
  InsertRankablesRankableEvent_insert_rankables_rankable_event_returning
} from "rankables/gql/mutations/__generated__/InsertRankablesRankableEvent";

import {
  DeleteRankablesRankableEventByPk_delete_rankables_rankable_event_by_pk
} from "rankables/gql/mutations/__generated__/DeleteRankablesRankableEventByPk";

const HASURA_TYPENAME = "rankables_rankable_event";
export default class RankableEvent extends RankableObject implements ApolloModel, HasuraModel<RankableEvent> {

  @tracked start: string;
  @tracked end: string;
  @tracked rankable_id: string;
  @tracked title: string | null;
  @tracked description: string | null;
  @tracked data: {
    rankings: string[] | null;
  }
  __typename: typeof HASURA_TYPENAME = HASURA_TYPENAME;

  /** Local only attributes */
  apollo?: ApolloService;
  justAdded!: boolean;

  constructor(data: Partial<ApolloModel>) {
    super(data);
    this.start = data.start;
    this.end = data.end;
    this.rankable_id = data.rankable_id;
    this.title = data.title as string | null;
    this.description = data.description as string | null;
    this.data = data.data;
    return this;
  }

  // Hasura
  static async findAll(apollo: ApolloService, options: any = { fetchPolicy: "network-only" }): Promise<RankableEvent[]> {
    let queryResult: ApolloQuery = await apollo.query(assign({
      query: RankablesRankableEventQ
    }, options));

    return queryResult[HASURA_TYPENAME].map((record: ApolloModel) => {
      let model = new RankableEvent(record);
      model.apollo = apollo;
      return model;
    });
  }
  static async findRecord(apollo: ApolloService, id: string, options?: any): Promise<RankableEvent | null> {
    let record: RankableEvent;

    try {
      record = await apollo.query(Object.assign({
        query: rankableEventQueryByPk,
        variables: { id: id }
      }, options), `${HASURA_TYPENAME}_by_pk`);
    } catch(e) {
      return null;
    }

    if (!record) {
      return null;
    }

    let rankableEvent = new RankableEvent(record);
    rankableEvent.apollo = apollo;
    return rankableEvent;
  }

  async updateRecord(this: RankableEvent, data: Partial<ApolloModel>, options: any = { fetchPolicy: "network-only" }): Promise<RankableEvent> {
    if (this.apollo) {
      let keys = Object.keys(this.constructor.prototype).pushObjects(
        Object.keys(this.constructor.prototype.constructor.superclass.prototype)
      );

      let apolloObjectState = reduce(keys, (obj: any, key: string) => {
        //@ts-ignore
        obj[key] = this[key];
        return obj;
      }, {});

      let variables = assign(apolloObjectState, data);

      let mutation: InsertRankablesRankableEvent_insert_rankables_rankable_event = await this.apollo.mutate(assign({
        mutation: rankableEventMutation,
        variables: variables
      }, options), `insert_${this.__typename}`)

      return mutation.returning.map((record: InsertRankablesRankableEvent_insert_rankables_rankable_event_returning) => {
        return new RankableEvent(record);
      })[0];
    }

    throw "Apollo not loaded into model";
  }

  // TODO: Set this up such that optional fields are actually  reflected in the type, e.g. Partial,
  // but makes nullable fields optional and makes everything else required.
  static async createRecord(apollo: ApolloService, data: Partial<ApolloModel>): Promise<RankableEvent> {
    let id = uuidv4();

    if (!data.id ) {
      data.id = id;
    }

    if (!data.updated_on) {
      data.updated_on = new Date();
    }

    if (!data.created_on) {
      data.created_on = new Date();
    }

    if (!data.data) {
      data.data = {};
    }

    let mutation: InsertRankablesRankableEvent_insert_rankables_rankable_event = await apollo.mutate({
      mutation: rankableEventMutation,
      variables: data
    }, `insert_${HASURA_TYPENAME}`);

    let record = new RankableEvent(mutation.returning[0]);
    record.apollo = apollo;
    return record;
  }

  async destroyRecord(this: RankableEvent, options = {}): Promise<DeleteRankablesRankableEventByPk_delete_rankables_rankable_event_by_pk> {
    if (this.apollo) {
      //@ts-ignore
      let deletion: DeleteRankablesRankableEventByPk_delete_rankables_rankable_event_by_pk = await this.apollo.mutate(Object.assign({
        mutation: rankableEventDeleteMutation,
        variables: {
          id: this.id
        }
      }, options), `delete_${this.__typename}_by_pk`);

      return deletion;
    } else {
      throw "Apollo not set onto model";
    }
  }

  //End Hasura
  static recordFindByPkQuery = rankableEventQueryByPk;
}
