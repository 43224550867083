import Service, { inject as service }  from "@ember/service";
import { get } from "@ember/object";
import { dasherize } from "@ember/string";
import { assign } from "lodash";
import RankableObject from "rankables/models/rankable-object";

//@ts-ignore
import { v4 as uuidv4 } from 'uuid';

import ApolloService from "ember-apollo-client/services/apollo";

export default class HasuraService extends Service {

  @service declare apollo: ApolloService;

  /**
   * Finds all records of the specified type
   *
   * @param type - Type of record, e.g. rankable
   * @param cb - An optional callback
   *
   */
  async findAll(this: HasuraService, type: string, options: any = { fetchPolicy: "network-only" }): Promise<any> {
    let model = require(`rankables/models/${dasherize(type)}`).default;

    return model.findAll(this.apollo, options);
  }

  async destroyRecord(this: HasuraService, record: HasuraModel<RankableObject>, options = {}): Promise<any> {
    return record.destroyRecord(options);
  }

  /**
   * Makes the Hasura GraphQL request, and optionally casts all data to the given type's model, if
   * defined.
   */
  async query(
    gql: {query: any, variables?: { [index: string]: string|number } , name: string}, 
    options: any = { fetchPolicy: "network-only" }
  ): Promise<any> {
    let records = await this.apollo.query(Object.assign({
      query: gql.query,
      variables: gql.variables
    }, options), gql.name);

    let newRecords = records.map((record: any) => {
      let model = require(`rankables/models/${record.__typename.split("_")[1]}`).default;
      let finalModel = new model(record);
      finalModel.apollo = this.apollo;
      return finalModel;
    });

    return newRecords;
  }

  /**
   * Makes the Hasura GraphQL request, and optionally casts all data to the given type's model, if
   * defined.
   */
  async watchQuery(gql: {query: any, variables?: { [index: string]: string|number } , name: string}, options?: any): Promise<any> {
    let records = await this.apollo.watchQuery(Object.assign({
      query: gql.query,
      variables: gql.variables
    }, options), gql.name);

    let newRecords = records.map((record: any) => {
      let model = require(`rankables/models/${record.__typename.split("_")[1]}`).default;
      return new model(record);
    });

    return newRecords;
  }


  /**
   * Creates the record in GraphQL and returns the result.
   */
  async createRecord(type: string, data: any): Promise<any> {
    let model = require(`rankables/models/${dasherize(type)}`).default;

    if (model.createRecord) {
      return model.createRecord(this.apollo, data);
    }

    let id = uuidv4();

    if (!data.id ) {
      data.id = id;
    }

    if (!data.updated_on) {
      data.updated_on = new Date();
    }

    if (!data.created_on) {
      data.created_on = new Date();
    }

    let mutation = await get(this, "apollo").mutate({
      mutation: model.recordUpsertionMutation,
      variables: model.recordUpsertMutationVariables(data)
    }, `insert_${model.graphQlName}`);

    return mutation.returning;
  };


  /**
   * Finds the record of the specified type matching the ID
   *
   * @param type - Type of model, e.g. "rankable_roup"
   * @param id - The UUID, e.g. 123-123-123
   * @param options - Optional options
   */
  async findRecord(type: string, id: string, options?: any): Promise<any> {
    let model = require(`rankables/models/${dasherize(type)}`).default;
    if (model.findRecord) {
      return model.findRecord(this.apollo, id, options);
    }

    /**
     * The queryResult refers to the Hasura/GraphQL response data
     */

    let record;

    try {
      record = await this.apollo.query(Object.assign({
        query: model.recordFindByPkQuery,
        variables: { id: id }
      }, options), `${model.graphQlName}_by_pk`);
    } catch(e) {
      return null;
    }

    return new model(record);

  }


  /**
   * Updates the record in Hasura and returns the result
   */
  async updateRecord(record: any, data: any, options: any = { fetchPolicy: "network-only" }): Promise<any> {
    let model = record.constructor;
    if (record.updateRecord) {
      return record.updateRecord(data, options);
    }

     let mutation = await get(this, "apollo").mutate(assign({
      mutation: model.recordUpsertionMutation,
      variables: model.recordUpsertMutationVariables(assign(record,data))
    }, options), `insert_${model.graphQlName}`);

    return mutation.returning.map((record: any) => new model(record))[0];
  }

};

declare module "@ember/service" {
  interface Registry {
    "hasura": HasuraService
  }
}
