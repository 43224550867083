//@ts-ignore
import t from "tailored";
//@ts-ignore
import { assignWith, set as lodashSet} from "lodash/object";

import Rankable from "rankables/models/rankable";
import RankableGroup from "rankables/models/rankable-group";

const $ = t.variable();
const _ = t.wildcard();

/**
 * Resets all input elements in form, and the associated ember bindings
 * as long as they mutate onkeyup.
 *
 * @param Event - The form that the onreset is bound to
 * @returns - void
 *
 * @example-html
 * <form onreset={{action bubbleReset}}
 *  <input onkeyup={{action (mut val) value="target.value"}}>
 * </form>
 */
export function bubbleReset(event: any): void {
  event.target.querySelectorAll("input").forEach((element: HTMLInputElement) => {
    element.value = "";
    element.setAttribute("value", "");

    if (element.onkeypress) {
      element.onkeypress(event);
    } else if (element.onkeyup) {
      element.onkeyup(event);
    } else if (element.onkeydown) {
      element.onkeydown(event);
    }
  })
}


/**
 * Deletes the given model
 *
 * @param model - The model that is being deleted
 *
 * @example-input
 * deleteModel(Rankable{})
 *
 * @example-return
 * Promise
 *
 */
export function deleteModel(model: any) {
  model.deleteRecord();
  return model.save();
}


/**
 * Deletes the rankable group
 *
 * @param store - The data store
 * @param rankableGroup - The rankableGroup that is being deleted
 *
 */
export function deleteRankableGroup(hasura: any, rankableGroup: RankableGroup) {
  t.defmatch(
    t.clause([
      $, //hasura
      $, //rankable
    ], async (hasura: any, rankableGroup: RankableGroup) => {
      hasura.destroyRecord(rankableGroup);
    }),


    t.clause([_], () => {
      throw "Something went wrong";
    })

  )(hasura, rankableGroup);

  // Remove rankable from RankableGroup or parent Rankable
  // Re-rank and save all related Rankables' ranks
}

/**
 * Deletes the rankables
 *
 * @param store - The data store
 * @param rankable - The rankable that is being deleted
 *
 */
export async function deleteRankable(hasura: any, rankable: Rankable) {
  t.defmatch(
    t.clause([
      $, //hasura
      $, //rankable
    ], async (hasura: any, rankable: Rankable) => {
      await hasura.destroyRecord(rankable);

      // Delete the current rankable from the rankableGroup
      let rankableGroup: RankableGroup = await hasura.findRecord("rankable-group", rankable.rankable_group_id);
      let originalRankings: string[] = rankableGroup.data.rankings || [];
      let tenativeRankings = originalRankings.filter(id => id !== rankable.id);

      // Save the RankableGroup
      await hasura.updateRecord(rankableGroup, { data: { rankings: tenativeRankings } });

    }),

    t.clause([_], () => {
      throw "Something went wrong";
    })

  )(hasura, rankable);

  // Remove rankable from RankableGroup or parent Rankable
  // Re-rank and save all related Rankables' ranks
}
