import Controller from "@ember/controller";
import { inject as service } from "@ember/service";
import { action, set, get, notifyPropertyChange } from "@ember/object";
import { tracked } from "@glimmer/tracking";

import HasuraService from "rankables/services/hasura";
import RankableGroup from "rankables/models/rankable-group";
import Rankable from "rankables/models/rankable";
import Tag from "rankables/models/tag";
import { bubbleReset, deleteRankable } from "rankables/utils/actions";
import type RouterService from '@ember/routing/router-service';

//@ts-ignore
import t from "tailored";

const $ = t.variable();
const _ = t.wildcard();

export default class IndexTasksIndexController extends Controller {

  @service declare router: RouterService;
  @service declare hasura: HasuraService;

  get selectedTag(): Tag | undefined {
    const tagFilter = this.tag_filter;

    return this.tags.find((tag: Tag) => {
      return tag.id === tagFilter;
    })
  }

  // TODO: Remove this and use the count from GraphQL
  get totalRankables(): number {
    return this.rankables.length + this.additionalRankables.length;
  };

  get dropdownIndicator(): string {
    return t.defmatch(
      t.clause(["created_on"], () => "Creation Date"),
      t.clause(["updated_on"], () => "Last Modified Date"),
      t.clause(["title"], () => "Title"),
      t.clause(["rank"], () => "Rank"),
      t.clause([_], () => null)
    )(this.sort);
  }

  get ascending() {
    if (!get(this, "sort")) {
      return null;
    }

    return get(this, "direction") === "asc";
  }



  queryParams = ["sort", "direction"];

  @tracked 
  sort: "rank_value" | "created_on" | "updated_on" = "rank_value";

  @tracked
  direction: "desc" | "asc" = "desc";

  @tracked
  tag_filter: string | null = null;

  @tracked
  rankableGroup!: RankableGroup;

  @tracked
  rankableTitle?: string | null;

  @tracked
  rankableDescription?: string | null;

  @tracked
  rankablesQueryRequestParams!: { query: any, variables: any };

  @tracked
  temporaryRankableGroupTitleMessage?: string | null;

  @tracked
  temporaryRankableGroupDescriptionMessage?: string | null;

  @tracked
  additionalRankables: Rankable[];

  @tracked
  rankableGroupQuery!: any;

  @tracked
  rankables: Rankable[];

  @tracked
  tags: Tag[];

  @tracked
  moreRankables?: boolean = false;

  /*
   * New rankables as they're added.
   */

  constructor() {
    super(...arguments);
    this.rankables = [];
    this.tags = [];
    this.additionalRankables = [];
  }


  @action
  bubbleReset(event: Event) {
    bubbleReset(event);
  }

  @action
  async deleteRankable(this: IndexTasksIndexController, rankable: Rankable, event: MouseEvent) {
    event.preventDefault();

    deleteRankable(this.hasura, rankable);
    // DEBT: This might have performance issues with super large (>= 10,000) datasets, but
    // it's O(n) so it cannot be improved that significantly.
    this.rankables.removeObject(rankable);
    notifyPropertyChange(this, "rankables");
  }

  @action
  toggleDirection(this: IndexTasksIndexController) {
    if (get(this, "direction") === "desc") {
      this.router.transitionTo("index.tasks", {
        queryParams: { direction: "asc" }
      });
    } else {
      this.router.transitionTo("index.tasks", {
        queryParams: { direction: "desc" }
      });

    }
  }


  @action
  sortBy(this: IndexTasksIndexController, value: string) {
    let [ direction, sort ] = value.split("-");

    this.router.transitionTo("index.tasks", {
      queryParams: {
        direction,
        sort
      }
    });
  }

  /**
   * Loads more rankables using the saved parameters from
   * the initial request.
   */
  @action
  async loadMoreRankables(this: IndexTasksIndexController) {
    let rankables: Rankable[] = this.rankables;
    let lastRankable: Rankable = rankables[rankables.length - 1];

    let { query, variables } = this.rankablesQueryRequestParams;


    let direction: "asc" | "desc" = this.direction;
    let sortVariable: "rank_value" | "updated_on" | "created_on" = this.sort;
    let lastRankableValue: any = lastRankable[sortVariable];

    if (!variables[sortVariable]) {
      variables[sortVariable] = {};
      variables["created_on"] = {};
    }

    if (direction === "asc") {
      variables[sortVariable]["_gte"] = lastRankableValue;
      variables["created_on"]["_gt"] = lastRankable["created_on"];

    } else if (direction === "desc") {
      variables[sortVariable]["_lte"] = lastRankableValue;
      variables["created_on"]["_lt"] = lastRankable["created_on"];
    }

    // Get more rankables, accordingly to the sort
    let additionalRankables: Rankable[] = await this.hasura.query({
      query,
      variables,
      name: "rankables_rankable"
    });

    set(this, "additionalRankables", additionalRankables);
    notifyPropertyChange(this, "additionalRankables");

    if (additionalRankables.length > 0) {
      additionalRankables.forEach(rankable => get(this, "rankables").pushObject(rankable));
    }

    // Less than 10 means an additional request would yield no rankables due to our limit of 10
    // per batch of rankables.
    if (additionalRankables.length < 10) {
      set(this, "moreRankables", false);
    }

    return;
  }

  @action
  updateAndSave(
    this: IndexTasksIndexController,
    model: RankableGroup,
    attribute: string,
    value: string,
    event: Event): void {
      if (event) { event.preventDefault(); }
      const self: IndexTasksIndexController = this;
      t.defmatch(
        t.clause([$, "title", $], async (_model: RankableGroup, value: string) => {
          await self.rankableGroup.columnMutation("title", value);
          self.temporaryRankableGroupTitleMessage = "Changes saved";
          setTimeout(function() {
            self.temporaryRankableGroupTitleMessage = null;
          }, 3000)
        }),

        t.clause([$, "description", $], async (model: RankableGroup, value: string) => {
          let newRankableGroup: RankableGroup = await self.hasura.updateRecord(model, { description: value });
          //@ts-ignore 
          self.rankableGroup.description = newRankableGroup.description;
          self.temporaryRankableGroupDescriptionMessage = "Changes saved";
          setTimeout(function() {
            self.temporaryRankableGroupDescriptionMessage = null;
          }, 3000)

        }),

        t.clause([_], () => {
          throw "Something went wrong";
        })
      )(model, attribute, value)
    }

  @action
  async deleteRankableGroup(this: IndexTasksIndexController, rankableGroup: RankableGroup) {
    await rankableGroup.destroyRecord();
    this.router.transitionTo("index");
  }

  @action
  addNewRankable(
    this: IndexTasksIndexController,
    args: { rankableTitle: string,
      rankableDescription: string,
      rankableGroup: RankableGroup,
      hasura: any,
    }, event: Event) {

    let { rankableTitle, rankableDescription, rankableGroup, hasura } = args;
    event.preventDefault();

    t.defmatch(
      t.clause([{
        rankableTitle: $,
        rankableDescription: $,
        rankableGroup: $,
        hasura: $,
        event: $
      }], async (rankableTitle: string,
        rankableDescription: string,
        rankableGroup: RankableGroup,
        hasura: any,
        event: any) => {
          let rankable: Rankable = await hasura.createRecord("rankable", {
            title: rankableTitle,
            description: rankableDescription,
            "rankable_group_id": rankableGroup.id
          });

          bubbleReset(event);
          rankable.justAdded = true;
          this.rankables.unshiftObject(rankable);
          notifyPropertyChange(this, "rankables");

        }, (rankableTitle: string,
          rankableDescription: string,
          rankableGroup: RankableGroup,
          hasura: any,
          event: any) => {
            return Boolean(rankableTitle) &&
              Boolean(rankableDescription) &&
              Boolean(rankableGroup) &&
              Boolean(hasura) &&
              Boolean(event)
          }
      ),

      t.clause([{
        rankableTitle: $,
        rankableGroup: $,
        hasura: $,
        event: $
      }], async (rankableTitle: string,
        rankableGroup: RankableGroup,
        hasura: any,
        event: any) => {
          let rankable: Rankable = await hasura.createRecord("rankable", {
            title: rankableTitle,
            "rankable_group_id": rankableGroup.id
          });

          bubbleReset(event);
          this.rankables.unshiftObject(rankable);
          notifyPropertyChange(this, "rankables");

        }, (rankableTitle: string,
          rankableGroup: RankableGroup,
          hasura: any,
          event: any) => {
            return Boolean(rankableTitle) &&
              Boolean(rankableGroup) &&
              Boolean(hasura) &&
              Boolean(event)
          }
      ),


      t.clause([_], () => {
        console.log("Perhaps some sort of error later?");
      })

    )({ rankableTitle, rankableDescription, rankableGroup, hasura, event });
  }
}
