import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";

import Rankable from "rankables/models/rankable";
import RankableEvent from "rankables/models/rankable-event";
import HasuraService from "rankables/services/hasura";
import IndexCalendarController from "rankables/pods/index/calendar/controller";

import bootstrap from "bootstrap";

interface FullCalendarComponentArgs {
  loadEvents: IndexCalendarController["loadEvents"];
  addNewRankableEvent: IndexCalendarController["addNewRankableEvent"];
  editRankableEventDrop: IndexCalendarController["editRankableEventDrop"];
  deleteRankableEvent: IndexCalendarController["deleteRankableEvent"];
}

export default class FullCalendarComponent extends Component<FullCalendarComponentArgs> {

  @service declare hasura: HasuraService;

  /**
   * Edits in the title of the RankableEvent are temporarily stored here.
   */
  @tracked
  title?: string;

  /**
   * Edits in the description of the RankableEvent are temporarily stored here.
   */
  @tracked
  description?: string | null;

  /**
   * Edits in the start date datelocaltime of the RankableEvent are temporarily stored here
   */
  @tracked
  startDate?: string;

  /**
   * Edits in the end date datelocaltime of the RankableEvent are temporarily stored here
   */
  @tracked
  endDate?: string;

  /**
   * Refers to the Rankable that is loaded with a RankableEvent.
   * This is only used for creating the link when you click an existing
   * RankableGroup
   */
  @tracked
  rankableEventRankable!: Rankable;

  /**
   * Refers to the Rankable that's selected from the list of Rankables in the modal picker
   * once you select a datetime period of an existing RankableEvent from the calendar.
   */
  @tracked
  selectedRankable: Rankable | null;

  /**
   * Refers to the RankableEvent that's selected when you click an item on the calendar.
   */
  @tracked
  selectedRankableEvent: RankableEvent | null;

  /**
   * A function that's invoked when you edit a RankableEvent by moving the calendar item.
   * This is passed from the controller. It's bound to the 'eventDrop' hook in the
   * FullCalendar.Calendar instance.
   */
  @tracked
  editRankableEventDrop!: any;

  //TODO: Delete addNewRankableEvent!: (hash: any) => any;
  //TODO: Delete deleteRankableEvent!: (rankableEvent: any) => any;

  calendar: any; // TODO: Change this to use fullcalendar types
  modal!: any; // TODO: Change this to use bootstrap modal type;

  constructor(owner: any, args: any) {
    super(owner, args);
    this.selectedRankableEvent = null;
    this.selectedRankable = null;
  }

  @action
  insertElement(_element: HTMLElement) {
    let calendarEl = document.getElementById('full-calendar');

    //@ts-ignore
    let calendar = new FullCalendar.Calendar(calendarEl, {
      initialView: "timeGridWeek",
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      selectable: true,
      selectMirror: true,
      editable: true,
      events: this.args.loadEvents,
      eventClick: async function(this: FullCalendarComponent, info: any) {
        let rankableEvent: RankableEvent = await this.hasura.findRecord("rankable-event", info.event.id);
        let rankable: Rankable = await this.hasura.findRecord("rankable", rankableEvent.rankable_id);

        this.rankableEventRankable = rankable;
        this.selectedRankableEvent = rankableEvent;

        let myModal = new bootstrap.Modal(document.getElementById('editModal') as Element);
        myModal.show();

      }.bind(this),

      eventDrop: this.args.editRankableEventDrop,

      select: function(this: FullCalendarComponent, info: any) {
        this.selectedRankableEvent = null;
        this.selectedRankable = null;
        this.startDate = info.start;
        this.endDate = info.end;

        let myModal = new bootstrap.Modal(document.getElementById('exampleModal') as Element);
        myModal.show();
      }.bind(this)
    });

    this.calendar = calendar;
    calendar.render();
  }


  editRankableEvent!: IndexCalendarController["editRankableEvent"];

  /**
   * A function that's invoked when you edit a RankableEvent by modifying properties in the modal
   * This is passed from the controller.
   */
  @action
  async editRankableEventHandler(this: FullCalendarComponent): Promise<void> {
    let newRankableEvent: RankableEvent = await this.editRankableEvent({
      start: this.startDate || this.selectedRankableEvent!.start,
      end: this.endDate || this.selectedRankableEvent!.end,
      title: this.title || this.selectedRankableEvent!.title,
      description: this.description || this.selectedRankableEvent!.description,
      rankable_id: this.selectedRankable?.id || this.selectedRankableEvent!.rankable_id,
      id: this.selectedRankableEvent!.id
    });

    let fullCalendarEvent: any = this.calendar.getEventById(newRankableEvent.id);
    fullCalendarEvent.setDates(newRankableEvent.start, newRankableEvent.end);

    let myModal = bootstrap.Modal.getInstance(document.getElementById('editModal') as Element);
    if (!myModal) {
      myModal = new bootstrap.Modal(document.getElementById('editModal') as Element);
    }

    myModal.hide();
  }

  addNewRankableEvent!: IndexCalendarController["addNewRankableEvent"];

  @action
  async addNewRankableEventHandler(this: FullCalendarComponent) {
    let title: string | null = (document.getElementById("rankable-event-title") as HTMLInputElement).value;
    let description: string | null = (document.getElementById("rankable-event-description") as HTMLInputElement).value;
    let start: string | null = (document.getElementById("rankable-event-start-datetime") as HTMLInputElement).value;
    let end: string | null = (document.getElementById("rankable-event-end-datetime") as HTMLInputElement).value;
    let rankableId: string | null = this.selectedRankable!.id;

    start = new Date(start).toISOString();
    end = new Date(end).toISOString();

    await this.args.addNewRankableEvent({
      title: title,
      description: description,
      rankableId: rankableId,
      start: start,
      end: end
    });

    await this.calendar.refetchEvents();

    let myModal = bootstrap.Modal.getInstance(document.getElementById('exampleModal') as Element);
    if (!myModal) {
      myModal = new bootstrap.Modal(document.getElementById('exampleModal') as Element);
    }

    myModal.hide();
  }

  @action
  async deleteRankableEvent(this: FullCalendarComponent, rankableEvent: RankableEvent) {
    await this.args.deleteRankableEvent(rankableEvent);
    let fullCalendarEvent: any = this.calendar.getEventById(rankableEvent.id);
    fullCalendarEvent.remove();
    this.selectedRankableEvent = null;

    let myModal = bootstrap.Modal.getInstance(document.getElementById('editModal') as Element);
    if (!myModal) {
      myModal = new bootstrap.Modal(document.getElementById('editModal') as Element);
    }

    myModal.hide();
  }

}
