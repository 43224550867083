import Route from "@ember/routing/route";
import TopTenQuery from "../groups/top-ten-query.graphql";
import { inject as service } from "@ember/service";

import HasuraService from "rankables/services/hasura";

export default class IndexCalendarRoute extends Route {
  @service declare hasura: HasuraService;

  async model(this: IndexCalendarRoute) {
    return await this.hasura.query({
      query: TopTenQuery,
      variables: {},
      name: "rankables_rankable"
    })
  }
};
