import Route from "@ember/routing/route";

export default class ErrorRoute extends Route.extend({
}) {

  async setupController(controller: any) {
    //@ts-ignore
    controller.set("rankableGroupId", this.modelFor("index.tasks").params["rankable_group_id"])
  }
};
