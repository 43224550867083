import Route from "@ember/routing/route";
import { inject as service } from "@ember/service";
import { get } from "@ember/object";
import gqlQuery from "./query.graphql";
import { hash } from "rsvp";
import Rankable from "rankables/models/rankable";

import HasuraService from "rankables/services/hasura";

export default class IndexTasksRoute extends Route {

  @service declare hasura: HasuraService;

  async model(params: any) {
    //@ts-ignore
    const rankableGroup = await get(this, "hasura").findRecord("rankable-group", params["rankable_group_id"]);

    let rankables: Promise<Rankable[]> = get(this, "hasura").query({
      query: gqlQuery,
      variables: {
        rankable_group_id: rankableGroup.id
      },
      name: "rankables_rankable"
    });

    return hash({
      params: params,
      rankableGroup: rankableGroup,
      rankables: rankables,
    });
  };
}
