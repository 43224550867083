import { tracked } from "@glimmer/tracking";
import EmberObject from "@ember/object";

import { assign } from "lodash";

interface RankableObjectArgs {
  __typename?: string;
  id?: string;
  created_on?: string;
  updated_on?: string;
}

export default abstract class RankableObject extends EmberObject {

  @tracked __typename!: string;
  @tracked id!: string;
  @tracked created_on!: string;
  @tracked updated_on!: string;

  prototype: any;

  /** Local Attributes **/
  justAdded!: boolean;

  constructor(record: RankableObjectArgs) {
    super();
    assign(this, record);
    return this;
  }
}
