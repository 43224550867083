import Rankable from "rankables/models/rankable";

/**
 * Given a set of tentative rankings and the original rankings, reconcile them and update
 * all of the affected rankables.
 *
 * This function should be run after manually deleting an item from the Rankable or
 * RankableGroup's rankings array. So for example, if you have a RankableGroup with
 * ranking that equals: ['a', 'b', 'c'] you would delete RankableGroup 'a' from the ranking, 
 * resulting in the RankableGroup having rankings ['b', 'c']. Because of this change the Rankable
 * 'b', which previously had the rank 2 would need to be updated to 1. Rankable 'c' would be updated
 * to '2' and so forth.
 *
 * @param store - The Ember store
 * @param tentativeRankings - The tentative rankings, or rankings after a deletion yet to be saved
 * @param rankings - The rankings of the origin or ranking prior to modification
 * @returns Promise - The promise resolving to all of the affected rankings being updated.
 *
 * @example-input
 * resetRankings(
 *  <Store> { },
 *  ['b', 'c'],
 *  ['a', 'b', 'c']
 * )
 *
 * @example-output
 * Promise<>
 *
 */
export async function resetRankings(
  hasura: any,
  tentativeRankings: string[],
  rankable: Rankable): Promise<any> {
    let rankableIndex: number;

    // The new rankable is the first
    if (tentativeRankings.length === 0 ) {
      await hasura.updateRecord(rankable, {
        rank: 1,
        rank_value: 90000000
      });

    // The new rankable is ranked #1
    } else if (tentativeRankings[0] === rankable.id) {
      rankableIndex = 1;
      /**
       * Because the new rankable is going to be saved at #1, we want to add the rank_value
       * to the previous #1, which will be the 2nd item in the array, so we use a rankIndex of 1.
       */
      const newRankable: Rankable = await hasura.findRecord("rankable", tentativeRankings[rankableIndex]);
      const newRankableValue: number = newRankable.rank_value;

      await hasura.updateRecord(rankable, {
        rank: 1,
        rank_value: newRankableValue + 90000000
      });

    // The new rankable is last
    } else if (tentativeRankings[tentativeRankings.length - 1] === rankable.id) {
      rankableIndex = tentativeRankings.length - 2;
      /**
       * Because the new rankable is going to be saved at the last index, we want to add the
       * rank_value
       * to the previous last item, which will be the 2nd to last item in the array, so we use a rankIndex 
       * of the length minus 2.
       */
      const newRankable: Rankable = await hasura.findRecord("rankable", tentativeRankings[rankableIndex]);

      await hasura.updateRecord(rankable, {
        rank: rankableIndex + 1,
        rank_value: Math.floor(newRankable.rank_value / 2)
      });

      // Is neither first, nor last. In this situation the rank_value should be the floor of the
      // middle of the two values the rankable is now between.
    } else {
      rankableIndex = tentativeRankings.indexOf(rankable.id);
      const rankableLeft: Rankable = await hasura.findRecord("rankable", tentativeRankings[rankableIndex - 1]);
      const rankableRight: Rankable = await hasura.findRecord("rankable", tentativeRankings[rankableIndex + 1]);

      await hasura.updateRecord(rankable, {
        rank: rankableIndex + 1,
        rank_value: Math.floor((rankableLeft.rank_value + rankableRight.rank_value ) /2 )
      });

    }

  return;

}
