import RankableObject from "rankables/models/rankable-object";
import RankablesRankableQ from "rankables/gql/queries/get-rankable.graphql";
import rankableQueryByPk from "rankables/gql/queries/get-rankable-pk.graphql";
import rankableMutation from "rankables/gql/mutations/create-rankable.graphql";
import RankableTagModelsQuery from "rankables/pods/index/tasks/task/tag-models-query.graphql";
import rankableDeleteMutation from "rankables/gql/mutations/delete-rankable.graphql";
import RankableGroup from "./rankable-group";

import TagModel from "rankables/models/tag-model";

import ApolloService from "ember-apollo-client/services/apollo";
import { tracked } from "@glimmer/tracking";

import { v4 as uuidv4 } from 'uuid';
import { reduce, assign } from "lodash";

// Hasura Model autogen
import {
  RankablesRankable_rankables_rankable as ApolloModel,
  RankablesRankable as ApolloQuery
} from "rankables/gql/queries/__generated__/RankablesRankable";

import {
  InsertRankablesRankable_insert_rankables_rankable,
  InsertRankablesRankable_insert_rankables_rankable_returning
} from "rankables/gql/mutations/__generated__/InsertRankablesRankable";

import {
  DeleteRankablesRankableByPk_delete_rankables_rankable_by_pk
} from "rankables/gql/mutations/__generated__/DeleteRankablesRankableByPk";

// Queries
import rankablesGqlQuery from "rankables/pods/index/tasks/index/query.graphql";

const HASURA_TYPENAME = "rankables_rankable";
export default class Rankable extends RankableObject implements ApolloModel, HasuraModel<Rankable> {

  @tracked parent_rankable_id!: string;
  @tracked rankable_id!: string;
  @tracked rankable_group_id!: string;
  @tracked title!: string;
  @tracked description!: string;
  @tracked rank!: number;
  @tracked details!: string;
  @tracked rank_value!: number;
  __typename: typeof HASURA_TYPENAME = HASURA_TYPENAME;

  @tracked data: {
    rankings?: string[];
  } = {};

  @tracked settings: {
  } = {};

  @tracked attributes: {
  } = {};

  /** Local only attributes */
  apollo?: ApolloService;
  justAdded!: boolean;

  constructor(data: Partial<ApolloModel>) {
    super(data);
    return this;
  }

  static recordFindByPkQuery = rankableQueryByPk;
    static recordUpsertMutationVariables = function(model: Rankable) {
    return {
      id: model.id,
      data: model.data,
      created_on: model.created_on,
      updated_on: model.updated_on,
      rankable_group_id: model.rankable_group_id,
      parent_rankable_id: model.parent_rankable_id,
      rank: model.rank,
      rank_value: model.rank_value,
      title: model.title,
      description: model.description,
      details: model.details
    }
  }

  // Hasura actions
  static async findAll(apollo: ApolloService, options: any = { fetchPolicy: "network-only" }): Promise<Rankable[]> {
    let queryResult: ApolloQuery = await apollo.query(assign({
      query: RankablesRankableQ
    }, options));

    return queryResult[HASURA_TYPENAME].map((record: ApolloModel) => {
      let model = new Rankable(record);
      model.apollo = apollo;
      return model;
    });
  }

  static async findRecord(apollo: ApolloService, id: string, options?: any): Promise<Rankable | null> {
    let record: Rankable;

    try {
      record = await apollo.query(Object.assign({
        query: rankableQueryByPk,
        variables: { id: id }
      }, options), `${HASURA_TYPENAME}_by_pk`);
    } catch(e) {
      return null;
    }

    if (!record) {
      return null;
    }

    let rankable = new Rankable(record);
    rankable.apollo = apollo;
    return rankable;
  }

  async updateRecord(this: Rankable, data: Partial<ApolloModel>, options: any = { fetchPolicy: "network-only" }): Promise<Rankable> {
    if (this.apollo) {
      let keys = Object.keys(this.constructor.prototype).pushObjects(
        Object.keys(this.constructor.prototype.constructor.superclass.prototype)
      );

      let apolloObjectState = reduce(keys, (obj: any, key: string) => {
        //@ts-ignore
        obj[key] = this[key];
        return obj;
      }, {});

      let variables = assign(apolloObjectState, data);

      let mutation: InsertRankablesRankable_insert_rankables_rankable = await this.apollo.mutate(assign({
        mutation: rankableMutation,
        variables: variables
      }, options), `insert_${this.__typename}`)

      return mutation.returning.map((record: InsertRankablesRankable_insert_rankables_rankable_returning) => {
        return new Rankable(record);
      })[0];
    }

    throw "Apollo not loaded into model";
  }

  static async createRecord(apollo: ApolloService, data: Partial<ApolloModel>): Promise<Rankable> {
    let id = uuidv4();

    if (!data.id ) {
      data.id = id;
    }

    data.updated_on = null;

    if (!data.created_on) {
      data.created_on = new Date();
    }

    if (!data.data) {
      data.data = {};
    }

    let mutation: InsertRankablesRankable_insert_rankables_rankable = await apollo.mutate({
      mutation: rankableMutation,
      variables: data
    }, `insert_${HASURA_TYPENAME}`);

    let record = new Rankable(mutation.returning[0]);
    record.apollo = apollo;
    return record;
  }

  /**
   * Queries and sets all models to include apollo
   */
  static async query(service: any, query: any, variables: { [index: string]: string | number } ) {
    let results: Rankable[] = await service.query({
      variables: variables,
      query: query
    });

    results.forEach((rankable) => {
      rankable.apollo = service;
    });

    return results;
  }


  async destroyRecord(this: Rankable, options = {}): Promise<DeleteRankablesRankableByPk_delete_rankables_rankable_by_pk> {
    if (this.apollo) {
      let deletion: DeleteRankablesRankableByPk_delete_rankables_rankable_by_pk = await this.apollo.mutate(Object.assign({
        mutation: rankableDeleteMutation,
        variables: {
          id: this.id
        }
      }, options), `delete_${this.__typename}_by_pk`);

      return deletion;
    } else {
      throw "Apollo not set onto model";
    }
  }

  // End hasura actions

  /**
   *  Returns child rankables
   */
  async getChildRankablesQuery(this: Rankable, service: any, options: any): Promise<Rankable[]> {
    let variables: any = {
      parent_rankable_id: { _eq: this.id }
    }

    if (options?.order_by) {
      variables["order_by"] = options.order_by;
    }

    // Generally this where is used if there's a filter on a list of rankables, usually as a result
    // of a tag filter
    if (options?.where) {
      // TODO: If necessary we will want to make this not hardcoded
      variables["rankable_ids"] = options.where["id"]["_in"];


      if (variables["rankable_ids"] === null) {
        delete variables["rankable_ids"];

      }
    }

    return service.query({
      query: rankablesGqlQuery,
      variables,
      name: "rankables_rankable"
    });
  }

  async getRankableGroup(this: Rankable, service: any, options?: any): Promise<RankableGroup> {
    let query = await service.findRecord("rankable_group", this.rankable_group_id, options);
    return query.exec();
  }

  /**
   * Returns a Rankable that resolves to the parent for the given Rankable
   */
  async getParentRankableQuery(this: Rankable, service: any): Promise<Rankable | null> {
    if (!this.parent_rankable_id) {
      return null;
    }

    let query = service.findRecord("rankable", this.parent_rankable_id || "");
    return query;
  }

  getTagModels(this: Rankable, service: any): Promise<TagModel[]> {
    return service.query({
      query: RankableTagModelsQuery,
      variables: {
        rankable_id: this.id
      },
      name: "rankables_tag_model"
    });
  }
};
