import { helper } from "@ember/component/helper";
//@ts-ignore
import gradient from "random-gradient";

function randomGradient(args: any) {
  try {
    return gradient(args.join(""));
  } catch(e) {
    return "";
  }
}

export default helper(randomGradient);
