import Controller from "@ember/controller";
import Tag from "rankables/models/tag";
import { tracked } from "@glimmer/tracking";
import { get } from "@ember/object";
import { inject as service } from "@ember/service";
import { bubbleReset } from "rankables/utils/actions";
import { A } from "@ember/array";

import IndexTasksTagsRoute from "./route";
import HasuraService from "rankables/services/hasura";

import { omitBy, isUndefined } from "lodash";

//@ts-ignore
import t from "tailored";


const $ = t.variable();
const _ = t.wildcard();

export default class IndexTasksTagsController extends Controller {

  @service declare hasura: HasuraService;

  get dropdownIndicator(): string {
    return t.defmatch(
      t.clause(["created_on"], () => "Creation Date"),
      t.clause(["updated_on"], () => "Last Modified Date"),
      t.clause(["title"], () => "Title"),
      t.clause(["rank"], () => "Rank"),
      t.clause([_], () => null)
    )(this.sort);
  };

  get ascendin(): boolean {
    if (!this.sort) {
      return false;
    }

    return this.direction === "asc";
  }


  route!: IndexTasksTagsRoute;

  queryParams = ["sort", "direction"];

  @tracked errors: any[];

  @tracked sort: "created_on" | "updated_on" | null = "created_on";

  @tracked direction: "desc" | "asc" | null = "desc";

  @tracked tagName?: string | null;
  @tracked tagDescription?: string | null;

  @tracked temporaryTagNameMessage?: string | null;
  @tracked temporaryTagDescriptionMessage?: string | null;

  @tracked tags: Tag[];

  constructor() {
    super(...arguments);
    this.errors = A([]);
    this.tags = A([]);
  }

  actions = {

    bubbleReset(event: Event) {
      bubbleReset(event);
    },

    deleteTag(this: IndexTasksTagsController, tag: Tag) {
      this.tags.removeObject(tag);
      tag.destroyRecord();
    },

    toggleDirection(this: IndexTasksTagsController) {
      if (get(this, "direction") === "desc") {
        this.route.transitionTo("index.tasks", {
          queryParams: { direction: "asc" }
        });
      } else {
        this.route.transitionTo("index.tasks", {
          queryParams: { direction: "desc" }
        });

      }
    },

    sortBy(this: IndexTasksTagsController, value: string) {
      let [ direction, sort ] = value.split("-");

      this.route.transitionTo("index.tasks.tags", {
        queryParams: {
          direction,
          sort
        }
      });
    },

    async updateAndSaveModelHash(
      this: IndexTasksTagsController,
      model: Tag,
      hash: any,
      editing: any,
    ): Promise<void> {
      let newRecord: Tag = await this.hasura.updateRecord(model, omitBy(hash, isUndefined));
      model = newRecord;
      editing();
    },

    updateAndSave(
      this: IndexTasksTagsController,
      model: Tag,
      attribute: string,
      value: string,
      event: Event): void {
        if (event) { event.preventDefault(); }
        t.defmatch(
          t.clause([$, "name", $], async (model: Tag, value: string) => {
            try {
              await this.hasura.updateRecord(model, { name: value });
              this.temporaryTagNameMessage = "Changes saved";
              setTimeout(() => {
                this.temporaryTagNameMessage = null;
              }, 3000)
            } catch(e: any) {
              this.errors = e.errors;
            } finally {
            }
          }),

          t.clause([$, "description", $], async (model: Tag, value: string) => {
            try {
              await this.hasura.updateRecord(model, { description: value });
              this.temporaryTagDescriptionMessage = "Changes saved.";
              setTimeout(() => {
                this.temporaryTagDescriptionMessage = null;
              }, 3000)
            } catch(e: any) {
              this.errors = e.errors;
            } finally {
            }


          }),

          t.clause([_], (e: any) => {
            console.log(e);
            throw "Something went wrong";
          })
        )(model, attribute, value)
      },

    addNewTag(
      this: IndexTasksTagsController,
      tagName: string,
      tagDescription: string,
      tagModelId: string,
      hasura: any,
      event: FocusEvent) {
      event.preventDefault();

      t.defmatch(
        t.clause([{
          tagName: $,
          tagDescription: $,
          tagModelId: $,
          hasura: $,
          event: $
        }], async (tagName: string,
          tagDescription: string,
          tagModelId: string,
          hasura: any,
          event: any) => {
            try {
              let tag: Tag = await hasura.createRecord("tag", {
                name: tagName,
                description: tagDescription,
                rankable_group_id: tagModelId
              })

              this.tags.unshiftObject(tag);
            } catch(e: any) {
              this.errors = e.errors;
            } finally {
              const eventTarget: any = event.target;
              eventTarget.value = null;
              this.tagName = null;
              this.tagDescription = null;
            }
          }, (tagName: string,
            tagDescription: string,
            tagModelId: string,
            hasura: any,
            event: any) => {

              return Boolean(tagName) &&
                Boolean(tagDescription) &&
                Boolean(tagModelId) &&
                Boolean(hasura) &&
                Boolean(event)
            }
        ),

        t.clause([{
          tagName: $,
          tagModelId: $,
          hasura: $,
          event: $
        }], async (tagName: string,
          tagModelId: string,
          hasura: any,
          event: any) => {
            try {
              let tag: Tag = await hasura.createRecord("tag", {
                name: tagName,
                rankable_group_id: tagModelId
              });


              this.tags.unshiftObject(tag);
            } catch(e: any) {
              this.errors = e.errors;
            } finally {
              const eventTarget: any = event.target;
              eventTarget.value = null;
              this.tagName = null;
              this.tagDescription = null;
            }

          }, (tagName: string,
            tagModelId: string,
            hasura: any,
            event: any) => {

              return Boolean(tagName) &&
                Boolean(tagModelId) &&
                Boolean(hasura) &&
                Boolean(event)
            }
        ),

        t.clause([_], (_e: any) => {
          console.log("Perhaps some sort of error later?");
        })

      )({ tagName, tagDescription, tagModelId, hasura, event });
    }
  }
}
