import Route from "@ember/routing/route";
import { get, set } from "@ember/object";
import { hash } from "rsvp";
import { inject as service } from "@ember/service";

import HasuraService from "rankables/services/hasura";
import Tag from "rankables/models/tag";
import RankableGroup from "rankables/models/rankable-group";
import Rankable from "rankables/models/rankable";
import TagModel from "rankables/models/tag-model";

import tagModelsGqlQuery from "../index/tag-model-query.graphql";

import gqlQuery from "../index/query.graphql";

interface ParametersInterface {
  rankable_id: string;
  tag_filter: string;
  sort: "created_on" | "updated_on" | "rank_value";
  direction: "asc" | "desc";
}


export default class IndexTasksTaskRoute extends Route {
  @service declare hasura: HasuraService;
  queryParams = {
    sort: { refreshModel: true },
    direction: { refreshModel: true },
    show_unranked: { refreshModel: true },
    tag_filter: { refreshModel: true }
  };

  tag_filter?: string;

  async model(this: IndexTasksTaskRoute, params: ParametersInterface) {
    set(this, "tag_filter", params.tag_filter);

    /**
     * The general idea is this:
     * If there's a tag filter, get the list of the IDs, from TagModel
     * and then get the associated Rankables from the IDs and sort them
     * according to the filters specified by the user
     */

    // Get Tag Models
    // TODO: Once pagination is implemented this will also need to be paginated, as neccessary. 
    let filteredRankableIds: string[] | null = null

    if (params.tag_filter) {
      /** Tag Models Query **/
      let tagModelVariables = {
        tag_id: params.tag_filter
      };

      const tagModels: TagModel[] = await get(this, "hasura").query({
        query: tagModelsGqlQuery,
        variables: tagModelVariables,
        name: "rankables_tag_model"
      });

      /** End Tag Models Query **/

      filteredRankableIds = tagModels.map(tagModel => tagModel.rankable_id);
    }

    const rankable: Rankable = await get(this, "hasura").findRecord("rankable", params["rankable_id"]);

    const rankableGroup: RankableGroup = (this.modelFor("index.tasks") as any).rankableGroup;

    const tags: Tag[] = await rankableGroup.getTags(get(this, "hasura"));
    const currentTag: Tag | undefined = tags.find(tag => tag.id === params.tag_filter);

    let tagModels: TagModel[];
    if (currentTag) {
      tagModels = await currentTag.getTagModels(get(this, "hasura"));
    } else {
      tagModels = await rankable.getTagModels(get(this, "hasura"));
    }

    /** Rankables Query **/
    let rankablesGqlVariables: any = {
      rankable_group_id: rankableGroup.id,
      parent_rankable_id: { _eq: rankable.id },
      limit: 10
    };

    if (filteredRankableIds) {
      rankablesGqlVariables.rankable_ids = { _in: filteredRankableIds };
    }

    if (params.sort && params.direction) {
      let sortField: any = {};
      sortField[params.sort] = params.direction;

      rankablesGqlVariables["order_by"] = {};
      rankablesGqlVariables["order_by"][params.sort] = params.direction;

      if (params.sort === "rank_value") {
        rankablesGqlVariables["order_by"]["created_on"] = params.direction;
      }

    }

    let rankables: Rankable[] = await get(this, "hasura").query({
      query: gqlQuery,
      variables: rankablesGqlVariables,
      name: "rankables_rankable"
    });

    /** End Rankables Query **/

    return hash({
      rankables,
      rankable: rankable,
      rankableGroup: rankableGroup,
      rankablesQueryRequestParams: {
        query: gqlQuery,
        variables: rankablesGqlVariables
      },
      tags: rankableGroup.getTags(get(this, "hasura")),
      tagModels: tagModels,
      currentTag: currentTag
    });
  }

  async setupController(this: IndexTasksTaskRoute, controller: any, model: any) {
    window.scrollTo(0,0);
    controller.rankablesQueryRequestParams = model.rankablesQueryRequestParams;

    controller.selectedTag = model.currentTag;
    controller.rankableGroup = model.rankableGroup;
    controller.rankable = model.rankable;

    controller.rankableGroupTags = model.tags;

    let rankableTagModels: TagModel[] = model.tagModels;
    controller.rankableTagModels = rankableTagModels;

    controller.rankables = model.rankables;

    if (model.rankables.length >= 10) {
      controller.moreRankables = true;
    }
  };
}
