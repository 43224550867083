import Controller from "@ember/controller";
import { Registry, inject as service } from "@ember/service";
import { get } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import query from "./query.graphql";
//@ts-ignore
import { queryManager } from "ember-apollo-client";

import ApolloService from "ember-apollo-client/services/apollo";
import FirebaseService from "rankables/services/firebase";

export default class SignupController extends Controller {

  @service declare firebase: FirebaseService;
  @service declare apollo: ApolloService;
  @service declare router: Registry["router"];

  get unequalPasswords(): boolean {
    let password = this.password;
    let passwordConfirmation = this.passwordConfirmation;

    if (password && passwordConfirmation && password !== passwordConfirmation) {
      return true;
    }

    return false;
  }

  @tracked error: Error | null;
  @tracked username: string = '';
  @tracked password: string = '';
  @tracked passwordConfirmation: string = "";

  constructor() {
    super(...arguments);
    this.error = null;
  }

  actions = {

    async register(this: SignupController, username: string, password: string, confirmation: string, event: any) {
      event.preventDefault();

      try {
        await this.firebase.register(username, password, confirmation);
        let userId = get(this, "firebase").firebaseInstance.auth().currentUser.uid

        await this.apollo.mutate({
          mutation: query,
          variables: {
            meta_id: userId
          }
        }, "rankables_user");

        this.username = "";
        this.password = "";
        this.passwordConfirmation = "";
        this.router.transitionTo("index");
      } catch(e) {
        this.error = e;
      }
    }

  } }
