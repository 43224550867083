import Route from "@ember/routing/route";
import { hash } from "rsvp";
import RankableGroup from "rankables/models/rankable-group";
import Rankable from "rankables/models/rankable";
import Tag from "rankables/models/tag";
import TagModel from "rankables/models/tag-model";
import HasuraService from "rankables/services/hasura";

import { inject as service } from "@ember/service";
import { set, get } from "@ember/object";

import gqlQuery from "./query.graphql";
import tagsGqlQuery from "../tags/query.graphql";
import tagModelsGqlQuery from "./tag-model-query.graphql";


interface IndexTasksIndexRouteQueryParams {
  sort: "created_on" | "updated_on" | "rank_value";
  tag_filter: string;
  direction: "asc" | "desc";
}

export default class IndexTasksIndexRoute extends Route {

  @service declare hasura: HasuraService;

  queryParams = {
    sort: { refreshModel: true },
    direction: { refreshModel: true },
    tag_filter: { refreshModel: true }
  };

  tag_filter?: string;

  async model(params: IndexTasksIndexRouteQueryParams) {
    set(this, "tag_filter", params.tag_filter);

    /**
     * The general idea is this:
     * If there's a tag filter, get the list of the IDs, from TagModel
     * and then get the associated Rankables from the IDs and sort them
     * according to the filters specified by the user
     */

    // Get Tag Models
    // TODO: Once pagination is implemented this will also need to be paginated, as neccessary. 
    let filteredRankableIds: string[] | null = null

    if (params.tag_filter) {
      /** Tag Models Query **/
      let tagModelVariables = {
        tag_id: params.tag_filter
      };

      const tagModels: TagModel[] = await get(this, "hasura").query({
        query: tagModelsGqlQuery,
        variables: tagModelVariables,
        name: "rankables_tag_model"
      });

      /** End Tag Models Query **/

      filteredRankableIds = tagModels.map(tagModel => tagModel.rankable_id);
    }

    //@ts-ignore
    const taskParams = this.modelFor("index.tasks").params;

    const rankableGroup: RankableGroup = await get(this, "hasura").findRecord("rankable-group", taskParams["rankable_group_id"]);

    /** Rankables Query **/
    const variables: any = {
      rankable_group_id: rankableGroup.id,
      parent_rankable_id: { _is_null: true },
      limit: 10
    };

    if (filteredRankableIds) {
      variables.rankable_ids = { _in: filteredRankableIds };
    }

    if (params.sort && params.direction) {
      let sortField: any = {};
      sortField[params.sort] = params.direction;


      variables["order_by"] = {};
      variables["order_by"][params.sort] = params.direction;

      if (params.sort === "rank_value") { 
        variables["order_by"]["created_on"] = params.direction;
      }
    }

    let rankables: Promise<Rankable[]> = get(this, "hasura").query({
      query: gqlQuery,
      variables,
      name: "rankables_rankable"
    });

    /** End Rankables Query **/

    /** Tags Query **/
    let tags: Promise<Tag[]> = get(this, "hasura").query({
      query: tagsGqlQuery,
      variables: {
        rankable_group_id: rankableGroup.id
      },
      name: "rankables_tag"
    });
    /** End Tags Query **/

    return hash({
      rankableGroup,
      rankables,
      tags,
      rankablesQueryRequestParams: {
        query: gqlQuery,
        variables: variables
      }
    });
  };

  async setupController(controller: any, model: any) {
    this._super(controller, model);

    controller.setProperties(model);
    if (model.rankables.length >= 10) {
      controller.moreRankables = true;
    }
  }
};
