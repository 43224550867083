import { helper } from "@ember/component/helper";

/**
 * @param offset - Whether to return the "true" position, [0...n] or the
 * offsetted position [1...n + 1]
 */
function indexOf([array, value, offset]: [string[], string, boolean]) {
  /**
   * TODO: At some point this should be cached, so the same
   * indexOf calculations are not being made over and over again.
   */
  let position: number = array.indexOf(value);
  if (position === -1) {
    return -1;
  }

  if (offset === true) {
    return position + 1;
  } else {
    return position;

  }
}

export default helper(indexOf);
