import Controller from "@ember/controller";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import { A } from "@ember/array";

import RankableEventQuery from "./rankable-event.graphql";
import RankableEvent from "rankables/models/rankable-event";
import Rankable from "rankables/models/rankable";

import HasuraService from "rankables/services/hasura";

export default class IndexCalendarController extends Controller {

  @service declare hasura: HasuraService;

  queryParams = ["sort", "direction"];
  sort: "rank_value" | "created_on" | "updated_on" = "rank_value";
  direction: "desc" | "asc" = "desc";
  show_unranked: boolean = true;
  tag_filter: string | null = null;

  /**
   * Refers to a rankable that was manually added to the list.
   */
  submittedRankables: Rankable[];

  constructor() {
    super(...arguments);
    this.submittedRankables = A([]);
  }


  /**
   * When submitted the rankable is loaded from the rankable ID and is added as the top
   * suggestion. In addition this is used as a query parameter from a rankable (on the rankable
   * page).
   */

  @action
  async submitRankableId(this: IndexCalendarController, rankableId: string, event: Event): Promise<void> {
    event.preventDefault();
    let rankable: Rankable = await this.hasura.findRecord("rankable", rankableId);
    this.submittedRankables.pushObject(rankable);
  }

  /**
   * See: https://fullcalendar.io/docs/events-function
   * Passed into the full calendar component within the template.
   * Loads Rankable Events from the server
   */
  @action
  async loadEvents(this: IndexCalendarController, info: any, success: Function, _failure: Function) {
    let startStr: string = info.startStr;
    let endStr: string   = info.endStr;

    let rankableEventGqlVariables: any = {
      start: startStr,
      end: endStr
    };

    let rankableEvents: RankableEvent[] = await this.hasura.query({
      query: RankableEventQuery,
      variables: rankableEventGqlVariables,
      name: "rankables_rankable_event"
    });

    try {
      await success(rankableEvents);
    } catch(e) {}
  }

  /**
   * Deletes a rankable event
   */
  @action
  async deleteRankableEvent(rankableEvent: RankableEvent) {
    await this.hasura.destroyRecord(rankableEvent);
  }

  @action
  async editRankableEvent(changeInfo: Pick<RankableEvent, "id" | "start" | "end" | "title" | "description" | "rankable_id">): Promise<RankableEvent> {
    let rankableEventId: string = changeInfo.id;
    let start: string = new Date(changeInfo.start).toISOString();
    let end: string = new Date(changeInfo.end).toISOString();
    let title: string | null = changeInfo.title;
    let description: string | null = changeInfo.description;
    let rankableId: string = changeInfo.rankable_id;

    let hasura: HasuraService = this.hasura;
    let rankableEventQuery = await hasura.findRecord("rankable-event", rankableEventId);
    let rankableEvent: RankableEvent = await rankableEventQuery.exec();

    return await this.hasura.updateRecord(rankableEvent, {
      title: title,
      description: description,
      start: start,
      end: end,
      rankable_id: rankableId
    });
  }

  /**
   * Covers the situation in which RankableEvents are changed via the moving of the RankableEvent
   * in the calendar.
   *
   * @param info - EventDropInfo (https://fullcalendar.io/docs/eventDrop)
   * @returns Promise<void>
   */
  @action
  async editRankableEventDrop(this: IndexCalendarController, info: any): Promise<void> {
    let hasura = this.hasura;
    try {
      let rankableEventQuery = await hasura.findRecord("rankable-event", info.event.id);
      let rankableEvent: RankableEvent = await rankableEventQuery.exec();
      await this.hasura.updateRecord(rankableEvent, {
        start: info.event.startStr,
        end: info.event.endStr
      });

    } catch(e) {
      info.revert();
    }
  }

  @action
  async addNewRankableEvent(hash: any): Promise<RankableEvent> {
    let { title, description, start, end, rankableId } = hash;
    let hasura = this.hasura;
    let rankableEvent: RankableEvent = await hasura.createRecord("rankable-event", {
      title: title,
      description: description,
      rankable_id: rankableId,
      start: start,
      end: end
    }) as RankableEvent;

    return rankableEvent;
  }

}
