import EmberRouter from '@ember/routing/router';
import config from './config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function() {
  this.route("index", { path: "/" }, function() {
    // Unauthenticated
    this.route("login");
    this.route("signup", { path: "signup" });
    this.route("help", { path: "help" });

    // Authenticated
    this.route("logout");
    this.route("profile");
    this.route("authenticate");
    this.route("profile");
    this.route("settings");
    this.route("notifications");

    this.route("calendar");
    this.route("groups", { path: "rg" });
    this.route("tasks", { path: "/rg/:rankable_group_id" }, function() {
      this.route("settings");
      this.route("tags");
      this.route("task", { path: "/:rankable_id" }, function() {
        this.route("tags");
      });
      this.route("compare", { path: "/:rankable_id/compare" });
    });

  });
});

export default Router;
