import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { inject as service } from "@ember/service";

import FirebaseService from "rankables/services/firebase";

export default class IndexIndexRoute extends Route {
  @service declare firebase: FirebaseService;
  @service declare router: RouterService;

  async beforeModel(transition: any) {
    let unauthenticatedTargets: any = {
      "index.login": true,
      "index.signup": true
    }

    let user = await this.firebase.onAuthStatePromise || this.firebase.currentUser;
    let userIsUnauthenticated = !user;
    let inAuthenticatedRoute = !unauthenticatedTargets[transition.targetName];

    if (userIsUnauthenticated && inAuthenticatedRoute) {
      this.router.transitionTo("index.login");
    }

  }

  afterModel() {
    this.transitionTo("index.groups");
  }

};
