import Route from "@ember/routing/route";
import { set, get } from "@ember/object";
import { inject as service } from "@ember/service"
import IndexGroupsController from "./controller";

export default class IndexGroupsRoute extends Route.extend({
  hasura: service("hasura"),
}) {

  async model(this: IndexGroupsRoute) {
    return get(this, "hasura").findAll("rankable_group");
  };


  setupController(this: IndexGroupsRoute, controller: IndexGroupsController, model: any, transition: any) {
    set(controller, "newRankableGroups", []);
    super.setupController(controller, model, transition);
  }
};
