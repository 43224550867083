import tagModelMutation from "rankables/gql/mutations/create-tag-model.graphql";
import tagModelDeleteMutation from "rankables/gql/mutations/delete-tag-model.graphql";
import tagModelQueryByPk from "rankables/gql/queries/get-tag-model-pk.graphql";
import RankablesTagModelQ from "rankables/gql/queries/get-tag-model.graphql";

import ApolloService from "ember-apollo-client/services/apollo";
import RankableObject from "rankables/models/rankable-object";

// Hasura Model autogen
import {
  RankablesTagModel_rankables_tag_model as ApolloModel,
  RankablesTagModel as ApolloQuery
} from "rankables/gql/queries/__generated__/RankablesTagModel";

import {
  InsertRankablesTagModel_insert_rankables_tag_model,
  InsertRankablesTagModel_insert_rankables_tag_model_returning
} from "rankables/gql/mutations/__generated__/InsertRankablesTagModel";

import {
  DeleteRankablesTagModelByPk_delete_rankables_tag_model_by_pk
} from "rankables/gql/mutations/__generated__/DeleteRankablesTagModelByPk";

import { v4 as uuidv4 } from 'uuid';
import { tracked } from "@glimmer/tracking";
import { reduce, assign } from "lodash";

const HASURA_TYPENAME = "rankables_tag_model";
export default class TagModel extends RankableObject implements ApolloModel, HasuraModel<TagModel> {

  @tracked tag_id!: string;
  @tracked rankable_id!: string;
  __typename: typeof HASURA_TYPENAME = HASURA_TYPENAME;

  /** Local only variables **/
  justAdded!: boolean;
  apollo?: ApolloService;

  static recordFindByPkQuery = tagModelQueryByPk;
  static recordDeleteMutation = tagModelDeleteMutation;
  static recordDeleteMutationVariables = function(model: TagModel) {
    return {
      id: model.id
    }
  }

  static recordUpsertionMutation = tagModelMutation;
  static recordUpsertMutationVariables = function(model: TagModel) {
    return {
      id: model.id,
      created_on: model.created_on,
      updated_on: model.updated_on,
      tag_id: model.tag_id,
      rankable_id: model.rankable_id,
    }
  }

  // Hasura actions
  static async findAll(apollo: ApolloService, options: any = { fetchPolicy: "network-only" }): Promise<TagModel[]> {
    let queryResult: ApolloQuery = await apollo.query(assign({
      query: RankablesTagModelQ
    }, options));

    return queryResult[HASURA_TYPENAME].map((record: ApolloModel) => {
      let model = new TagModel(record);
      model.apollo = apollo;
      return model;
    });
  }

  static async findRecord(apollo: ApolloService, id: string, options?: any): Promise<TagModel | null> {
    let record: TagModel;

    try {
      record = await apollo.query(Object.assign({
        query: tagModelQueryByPk,
        variables: { id: id }
      }, options), `${HASURA_TYPENAME}_by_pk`);
    } catch(e) {
      return null;
    }

    if (!record) {
      return null;
    }

    let rankable = new TagModel(record);
    rankable.apollo = apollo;
    return rankable;
  }

  async updateRecord(this: TagModel, data: Partial<ApolloModel>, options: any = { fetchPolicy: "network-only" }): Promise<TagModel> {
    if (this.apollo) {
      let keys = Object.keys(this.constructor.prototype).pushObjects(
        Object.keys(this.constructor.prototype.constructor.superclass.prototype)
      );

      let apolloObjectState = reduce(keys, (obj: any, key: string) => {
        //@ts-ignore
        obj[key] = this[key];
        return obj;
      }, {});

      let variables = assign(apolloObjectState, data);

      let mutation: InsertRankablesTagModel_insert_rankables_tag_model = await this.apollo.mutate(assign({
        mutation: tagModelMutation,
        variables: variables
      }, options), `insert_${this.__typename}`)

      return mutation.returning.map((record: InsertRankablesTagModel_insert_rankables_tag_model_returning) => {
        return new TagModel(record);
      })[0];
    }

    throw "Apollo not loaded into model";
  }

  static async createRecord(apollo: ApolloService, data: Partial<ApolloModel>): Promise<TagModel> {
    let id = uuidv4();

    if (!data.id ) {
      data.id = id;
    }

    if (!data.updated_on) {
      data.updated_on = new Date();
    }

    if (!data.created_on) {
      data.created_on = new Date();
    }

    let mutation: InsertRankablesTagModel_insert_rankables_tag_model = await apollo.mutate({
      mutation: tagModelMutation,
      variables: data
    }, `insert_${HASURA_TYPENAME}`);

    let record = new TagModel(mutation.returning[0]);
    record.apollo = apollo;
    return record;
  }

  async destroyRecord(this: TagModel, options = {}): Promise<DeleteRankablesTagModelByPk_delete_rankables_tag_model_by_pk> {
    if (this.apollo) {
      let deletion: DeleteRankablesTagModelByPk_delete_rankables_tag_model_by_pk = await this.apollo.mutate(Object.assign({
        mutation: tagModelDeleteMutation,
        variables: {
          id: this.id
        }
      }, options), `delete_${this.__typename}_by_pk`);

      return deletion;
    } else {
      throw "Apollo not set onto model";
    }
  }


  //End Hasura

};
